import Swiper from 'swiper';
import { Autoplay, A11y } from 'swiper/modules';

export default class Footer {
  constructor(e) {
    this.$el = $(e);
    this.mySwiper = null;

    this.elementClasses = {
      swiperContainer: '.swiper-container',
    };
  }

  init() {
    if (this.$el.length) {
      this.addSwiper();
    }
  }

  addListeners() {
    $(window).on('resize', () => {
      this.eventResizeDestroySwiper();
    });
  }

  addSwiper() {
    this.mySwiper = new Swiper(this.$el.find(this.elementClasses.swiperContainer)[0], {
      modules: [Autoplay, A11y],
      slidesPerView: 3,
      slidesPerGroup: 3,
      autoplay: {
        delay: 3500,
      },
      breakpoints: {
        768: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
        1199: {
          slidesPerView: 7,
          slidesPerGroup: 7,
        },
      },
    });
  }

  eventResizeDestroySwiper() {
    if ($(window).width() > 1199) {
      if (this.mySwiper) {
        this.mySwiper.destroy();
        this.mySwiper = null;
      }
    } else if (!this.mySwiper) {
      this.addSwiper();
    } else {
      // do nothing
    }
  }
}

const $footer = $('.footer');
if ($footer.length) {
  $footer.each((_i, e) => {
    new Footer(e).init();
  });
}
