import { isDesktop, getPosition } from '../utils';

export default class AnimationExpandWidthSection {
  constructor(el) {
    this.$el = $(el);
    this.class = {
      startAniScroll: 'start-ani-scroll',
    };
    this.offsetTopModule = getPosition(this.$el.get(0)).y;
    this.heightWindow = $(window).innerHeight();
    this.heightModule = this.$el.innerHeight();
    this.positionFlag = 0;
  }

  init() {
    this.addListeners();
  }

  addListeners() {
    window.scrollAgent.scrollEvent((args) => {
      const scrollY = parseFloat(args.scroll.y.toFixed(2));
      if (this.positionFlag !== scrollY) {
        this.positionFlag = scrollY;
        this.heightWindow = $(window).innerHeight();
        this.offsetTopModule = getPosition(this.$el.get(0)).y;
        this.heightModule = this.$el.innerHeight();
        this.handleEventScrollExpandWidthSection();
      }
    });
  }

  handleEventScrollExpandWidthSection() {
    if (isDesktop()) {
      if (window.customScrollY + this.heightWindow > this.offsetTopModule) {
        const countOffset = window.customScrollY + this.heightWindow - this.offsetTopModule;
        const numberScrollTmp1 = this.offsetTopModule + this.heightModule - this.heightModule / 3;
        const numberScrollTmp2 = numberScrollTmp1 - this.offsetTopModule;
        let numberScrollTmp3 = (80 / numberScrollTmp2) * countOffset;
        if (numberScrollTmp3 > 80) {
          numberScrollTmp3 = 80;
        }
        this.percent = numberScrollTmp3 + 20;
        this.$el.css('width', `${this.percent}%`);
      }

      if (window.customScrollY + this.heightWindow > this.offsetTopModule + (this.heightModule * 0.70)) {
        this.$el.addClass(this.class.startAniScroll);
      } else {
        this.$el.removeClass(this.class.startAniScroll);
      }
    } else {
      this.$el.css('width', '');
      this.$el.removeClass(this.class.startAniScroll);
    }
  }
}

const $animationExpandWidthSection = $('.animation-expand-width-section');
if ($animationExpandWidthSection.length) {
  $animationExpandWidthSection.each((_i, e) => {
    new AnimationExpandWidthSection(e).init();
  });
}
