import { CLICK_EVENT_TRIGGER } from '../constants';

class LeavingPopup {
  constructor() {
    this.$el = $('.leaving-popup');

    this.classes = {
      isOpen: 'is-open',
      mouse: 'mouse',
    };

    this.dom = {
      $body: $('body'),
      $popupWrap: this.$el.find('.leaving-popup-card'),
      $closeButton: this.$el.find('.leaving-popup-card-close'),
      $linkButton: this.$el.find('.leaving-popup-card-link-button'),
      $linkUrl: this.$el.find('.leaving-popup-card-link-url'),
    };
    this.text = {
      dataEventLabel: 'data-event-label',
    };

    this.$prevTriggerElement = null;

    this.whitelist = window.HONDA_LEAVING_WHITELIST || [];

    this.whitelistArrayText = [
      'javascript:;', // eslint-disable-line
      'javascript:void(0);', // eslint-disable-line
      '#!',
      'tel:',
      'mailto:',
    ];

    this.lazyDone = false;

    this.trackingEventAttributes = [
      'data-event-trigger',
      'data-event-type',
      'data-event-category',
      'data-event-module',
      'data-event-label',
      'data-event-page',
      'data-event-destination',
      'data-event-download-title',
    ];

    this.openPopupEventListener = this.openPopupEventListener.bind(this);
  }

  init() {
    this.dom.$body.append(this.$el);
    this.addEventListeners();
  }

  addEventListeners() {
    this.dom.$closeButton.on('click', (event) => {
      this.closePopup(event);
    });

    this.dom.$linkButton.on('click', (event) => {
      this.closePopup(event);
    });

    $(document).on('click', 'a', this.openPopupEventListener);

    $(document).on('click mousemove wheel touchmove keydown input', () => {
      if (!this.lazyDone) {
        window.lazyLoadImage.lazyImmediately(this.$el.get(0));
        this.lazyDone = true;
      }
    });

    this.$el.on('keydown', this.ADAEvent.bind(this));
    $(window).on('keydown', this.windowKeyDownEvent.bind(this));
  }

  openPopupEventListener(ev) {
    const $elm = $(ev.currentTarget);
    const dontOpenPopup = $elm.data('dont-open-popup');
    const tagName = $elm.prop('tagName');
    if (tagName === 'A') {
      const href = $elm.attr('href');
      const url = new URL(href, window.location.href);
      const anchorHostname = url.hostname;

      // check href whitelist not include this url
      const filterWhitelist = this.whitelist.filter((item) => (href.indexOf(item) > -1));
      const filterWhitelistArrayText = this.whitelistArrayText.filter((item) => (href.indexOf(item) > -1));
      if (!filterWhitelist.length && !filterWhitelistArrayText.length
        && !$elm.is(this.dom.$linkButton) && !dontOpenPopup && anchorHostname !== window.location.hostname) {
        ev.preventDefault();
        this.$prevTriggerElement = $elm;
        this.openPopup(href, $elm);
      }
    }
  }

  openPopup(href, $elm) {
    // remove tracking event attributes
    this.trackingEventAttributes.forEach((attr) => {
      this.dom.$linkButton.removeAttr(attr);
    });

    let $dataElement = $elm;
    let label = $elm.attr(this.text.dataEventLabel);
    const $parentTracking = $elm.parents('[data-event-trigger-children], [data-event-trigger-children-link]');
    if (!$elm.attr(this.text.dataEventLabel) && $parentTracking.length) {
      $dataElement = $parentTracking;
      label = $elm.text();
    }

    // add tracking event attributes
    this.trackingEventAttributes.forEach((attr) => {
      let attrValue = $dataElement.attr(attr);
      if (attr === 'data-event-trigger') {
        attrValue = CLICK_EVENT_TRIGGER;
      } else if (attr === this.text.dataEventLabel) {
        attrValue = label;
      } else {
        // not things
      }

      if (attrValue) {
        this.dom.$linkButton.attr(attr, attrValue);
      }
    });

    this.dom.$linkButton.attr('data-force-tracking', 'true');

    this.dom.$linkButton.attr('href', href).attr('target', '_blank').focus();
    this.dom.$linkUrl.text(href);
    this.$el.addClass(this.classes.isOpen);

    if (!this.$prevTriggerElement.hasClass(this.classes.mouse)) {
      this.dom.$linkButton.focus();
    }
    window.scrollAgent.$scrollEvent.stop();
  }

  closePopup(event) {
    const $elementCurrentTarget = $(event.currentTarget);
    this.$el.removeClass(this.classes.isOpen);

    // focus to previous element
    if (this.$prevTriggerElement && !$elementCurrentTarget.hasClass(this.classes.mouse)) {
      this.$prevTriggerElement.focus();
      this.$prevTriggerElement = null;
    }
    window.scrollAgent.$scrollEvent.start();
  }

  ADAEvent(e) {
    const $target = $(e.target);

    // tab key
    if (e.keyCode === 9) {
      e.preventDefault();
      this.dom.$linkButton.focus();

      if ($target.is(this.dom.$linkButton)) {
        this.dom.$closeButton.focus();
      }
      if ($target.is(this.dom.$closeButton)) {
        this.dom.$linkButton.focus();
      }
    }
  }

  windowKeyDownEvent(e) {
    // esc key
    if (e.keyCode === 27) {
      this.closePopup(e);
    }
  }

}

new LeavingPopup().init();

export default LeavingPopup;