export default class CallBackLazy {
  constructor() {
    this.$html = $('html');
    this.classes = {
      bLoaded: 'b-loaded',
      lazyCampaign: 'lazy-campaign',
      lazy: 'lazy',
      customLazy: 'lazy-hidden lazy-feature',
    };

    this.attribute = {
      srcset: 'srcset',
      dataSrcset: 'data-srcset',
      src: 'src',
      dataSrc: 'data-src',
    };

    this.tags = {
      picture: 'picture',
      source: 'source',
    };

    this.elementClasses = {
      altText: '.alt-text',
    };
  }

  call(elementTmp, element) {
    if ($(element).hasClass(this.classes.lazy)) {
      this.lazyImg(elementTmp, element);
    }
  }

  callCampaign(elementTmp, element) {
    if ($(element).hasClass(this.classes.lazyCampaign)) {
      this.handleCampaign(elementTmp, element);
    }
  }

  handleCampaign(elementTmp, element) {
    const dataSrc = element.getAttribute(this.attribute.dataSrc);
    if (!dataSrc) {
      return;
    }

    const $pictureTag = $(element).closest(this.tags.picture);

    if (elementTmp === 'IMG') {
      this.handleCampaignImage($pictureTag);
    } else {
      this.setBackgroundImage(element, dataSrc);
    }

    this.finalizeElement(element, this.classes.lazyCampaign);
  }

  handleCampaignImage($pictureTag) {
    if ($pictureTag.length) {
      const $sourcePicture = $pictureTag.find(this.tags.source);
      $sourcePicture.each((_idx, elm) => {
        $(elm).attr(this.attribute.srcset, $(elm).attr(this.attribute.dataSrcset)).removeAttr(this.attribute.dataSrcset);
      });
    }
  }

  lazyImg(elementTmp, element) {
    const dataSrc = element.getAttribute(this.attribute.dataSrc);
    if (!dataSrc) {
      return;
    }

    const $pictureTag = $(element).closest(this.tags.picture);
    const isImageTag = elementTmp === 'IMG';

    if (isImageTag) {
      this.handleImageTag(element, dataSrc, $pictureTag);
    } else {
      this.setBackgroundImage(element, dataSrc);
    }

    this.finalizeElement(element, this.classes.lazy);
  }

  handleImageTag(element, dataSrc, $pictureTag) {
    if ($pictureTag.length) {
      this.updateSourceTags($pictureTag);
    }

    element.setAttribute(this.attribute.src, dataSrc);

    if ($(element).hasClass('screen-image-inner')) {
      this.preloadImage(dataSrc, element);
    }
  }

  updateSourceTags($pictureTag) {
    const $sourcePicture = $pictureTag.find(this.tags.source);
    $sourcePicture.each((_idx, elm) => {
      $(elm).attr(this.attribute.srcset, $(elm).attr(this.attribute.dataSrcset)).removeAttr(this.attribute.dataSrcset);
    });
  }

  setBackgroundImage(element, dataSrc) {
    $(element).css({ 'background-image': `url('${dataSrc}')` });
  }

  finalizeElement(element, lazyClass) {
    $(element)
      .addClass(this.classes.bLoaded)
      .removeClass(lazyClass)
      .removeClass(this.classes.customLazy)
      .removeAttr(this.attribute.dataSrc)
      .parents('a')
      .find(this.elementClasses.altText)
      .remove();
  }

  preloadImage(src, element) {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      $(element).trigger('imageLoaded');
    };
  }
}
