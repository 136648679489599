import { CONFIG_BASE } from './base';
import { CONFIG_DEV } from './development';
import { CONFIG_PROD } from './production';

export const checkEnv = () => {
  let env = 'prod';
  switch (window.location.hostname) {
    case 'localhost':
      env = 'dev';
      break;
    case '127.0.0.1':
      env = 'dev';
      break;
    case 'powersports-redesign-html.box.gravityapps.net':
    case 'powersports-redesign-html.box.gravity.codes':
      env = 'dev';
      break;
    default:
      break;
  }
  return env;
};

const getConfig = () => {
  let CONFIG = { ...CONFIG_BASE };
  switch (checkEnv()) {
    case 'dev':
      CONFIG = { ...CONFIG_BASE, ...CONFIG_DEV };
      break;
    case 'prod':
      CONFIG = { ...CONFIG_BASE, ...CONFIG_PROD };
      break;

    default:
      break;
  }
  return CONFIG;
};

export const CONFIG = getConfig();
