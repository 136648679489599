import { getPosition } from '../utils';

export default class AnimationScrollPage {
  constructor() {
    this.classes = {
      setAnimation: 'set-animation',
      registerAnimation: 'register-animation',
    };
    this.$el = $(`.animation:not(.${this.classes.registerAnimation})`);
    this.offset = window.innerHeight;
    this.windowTop = null;
    this.topCoords = null;
    this.positionFlag = 0;
  }

  init() {
    window.mainPin = 0;
    this.animationEle();
    this.addListeners();
    this.$el.addClass(this.classes.registerAnimation);
  }

  addListeners() {
    $(window).on('resize', () => {
      this.animationEle();
      this.doneAnimation();
    });
    window.scrollAgent.scrollEvent((args) => {
      const scrollY = parseFloat(args.scroll.y.toFixed(2));
      if (this.positionFlag !== scrollY) {
        this.positionFlag = scrollY;
        this.windowTop = scrollY;
        this.animationEle();
      }
    });
  }

  doneAnimation() {
    this.$el.addClass(this.classes.setAnimation);
  }

  setAnimation($elm) {
    if ($elm.hasClass(this.classes.setAnimation)) {
      return false;
    }
    this.topCoords = getPosition($elm.get(0)).y;
    if ($elm.hasClass('ani-delay-120')) {
      this.topCoords += 120;
    }
    if ($elm.hasClass('ani-delay-250')) {
      this.topCoords += 120;
    }
    if ($elm.hasClass('ani-delay-350')) {
      this.topCoords += 120;
    }
    if ($elm.hasClass('ani-delay-500')) {
      this.topCoords += 120;
    }
    const scrollTop = $elm.parents('.main-content').length ? this.windowTop - window.mainPin : this.windowTop;
    if (scrollTop > this.topCoords - this.offset) {
      this.dispatchEventAn($elm);
      if ($elm.hasClass('mod-features')) {
        setTimeout(() => {
          $elm.addClass(this.classes.setAnimation);
        }, 500);
      } else {
        $elm.addClass(this.classes.setAnimation);
        setTimeout(() => {
          if (typeof window.scrollAgent.$scrollEvent.update !== 'undefined') {
            window.scrollAgent.$scrollEvent.update();
          }
        });
      }
    }
    return true;
  }

  animationEle() {
    this.offset = window.innerHeight;
    if ($('body').hasClass('has-animation')) {
      this.$el.each((_index, ele) => {
        this.setAnimation($(ele));
      });
    }
    window.setAnimation = this.setAnimation.bind(this);
  }

  dispatchEventAn($ele) {
    let event;
    if (typeof CustomEvent === 'function') {
      event = new CustomEvent('loadAnimation', {
        detail: {
          $element: $ele,
        },
      });
    } else {
      event = document.createEvent('CustomEvent');
      event.initCustomEvent('loadAnimation', true, true, $ele);
    }
    window.dispatchEvent(event);
  }
}
