export default class ButtonAnimation {
  constructor() {
    this.$buttons = $('[class*="btn-animation-"]');
  }

  int() {
    this.$buttons.each((_i, e) => {
      const $button = $(e);
      const classes = $button.attr('class').split(' ');
      const animationClass = classes.find((c) => c.startsWith('btn-animation-'));
      if (animationClass && !$button.find('span').length) {
        const suffix = animationClass.replace('btn-animation-', '');
        let html = '';
        const textButton = $button.text();
        switch (suffix) {
          case 'arrow':
            html = `<span class="relative overflow-hidden inline-flex">
            <span class="icomoon icon-arrow-right-animation"></span>
            <span class="icomoon icon-arrow-right-animation absolute -left-11"></span>
          </span>`;
            break;
          case 'compare':
            html = `<span class="relative overflow-hidden inline-flex">
           <span class="icomoon icon-arrow-lr-animation"><span class="path1 inline-block"></span><span class="inline-block path2"></span></span>
           <span class="icomoon icon-arrow-lr-animation absolute"><span class="path1 inline-block relative -right-11"></span><span class="inline-block path2 relative -left-11"></span></span>
         </span>`;
            break;
          case 'help':
            html = ` <span class="icomoon icon-question-animation"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>`;
            break;
          case 'build':
            html = ` <span class="icomoon  text-h6-2xl icon-setting-animation"></span>`;
            break;
          case 'search':
            html = `<span class="icomoon icon-search-animation"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>`;
            break;
          case 'download':
            html = `<span class="relative overflow-hidden inline-flex">
            <span class="icomoon icon-download-animation"><span class="path1"></span><span class="path2 inline-block"></span></span>
          </span>`;
            break;
          case 'trims':
            html = `<span class="icomoon icon-offer-animation"><span class="path1 "></span><span class="path2 inline-block"></span></span>`;
            break;
          default:
            html = `<span class="text">${textButton}</span>`;
            $button.text('');
            break;
        }
        $button.append(html);
      }
    });
  }
}

new ButtonAnimation().int();
