import './components/TrackingEvent.js';
import create from './libs/core';
import { getPosition, getCsrfTokenFromApi } from './utils';
import modules from './modules';
import AnimationScrollPage from './components/AnimationScrollPage';
import ADA from './components/ADA';
// components
import './components/BrowserDetection.js';
import './components/CheckDevice';
import './components/ConvertSvg';
import './components/UserAgent';
import './components/LazyLoadImage';
import './components/header/Header';
import './components/Footer';
import './components/Tooltip';
import './components/LeavingPopup';
import './components/HoverLoopImage';
import './components/ModAccordion';
import './components/BadgeZipcode';
import './components/AnimationExpandWidthSection';
import './components/BackgroundVideo';
import './components/ButtonAnimation';

new ADA().init();
window.animationScrollPage = new AnimationScrollPage();
window.animationScrollPage.init();
document.addEventListener(
  'DOMContentLoaded',
  async () => {
    function loadScript(url) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      document.head.appendChild(script);
    }
    let flagYoutube = false;
    let flagVimeo = false;
    let flagKaltura = false;
    let checkScroll = false;
    let timeOut = 2000;

    window.scrollAgent.scrollEvent(() => {
      if (!checkScroll) {
        timeOut = 0;
        checkScroll = true;
      }
    });

    $(window).on('scroll', () => {
      if (!checkScroll) {
        timeOut = 0;
        checkScroll = true;
      }
    });

    const dataSrc = document.querySelectorAll('[data-src].js-iframe-video, .banner-bg-video');
    // Loop through the selected elements
    // eslint-disable-next-line consistent-return
    dataSrc.forEach((element, index) => {
      if (getPosition($(element).get(0)).y < window.innerHeight && !checkScroll && index === 0) {
        timeOut = 0;
        if ($(element).hasClass('banner-bg-video') && $(element).parents('.swiper-slide').index() > 0 ) {
          timeOut = 2000;
        }
        checkScroll = true;
        return false;
      }
    });
    setTimeout(() => {
      dataSrc.forEach((element) => {
        const urlString = element.getAttribute('data-src');

        if ((urlString.indexOf('youtube.com') !== -1 || urlString.indexOf('youtu.be') !== -1) && !flagYoutube) {
          loadScript('https://www.youtube.com/iframe_api');
          flagYoutube = true;
        }

        if (urlString.indexOf('vimeo.com') !== -1 && !flagVimeo) {
          loadScript('https://player.vimeo.com/api/player');
          flagVimeo = true;
        }

        if (urlString.indexOf('kaltura.com') !== -1 && !flagKaltura) {
          loadScript(`https://cdnapisec.kaltura.com/p/${window.HONDA_KALTURA_SETTINGS.partnerId}/embedPlaykitJs/uiconf_id/${window.HONDA_KALTURA_SETTINGS.playerId}`);
          flagKaltura = true;
        }
      });
    }, timeOut);
    create(modules);
    // Load the CSRF token from the API
    getCsrfTokenFromApi();
  },
  false,
);