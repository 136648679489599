import DOMPurify from 'dompurify';

export class ConvertSvg {
  constructor() {
    this.$hasSvg = $('img.img-svg:not(.lazy)');
  }

  appendSvg(data, $img, isBtnAni, $parentImage ) {
    const imgID = $img.attr('id');
    const imgClass = $img.attr('class');
    const cleanData = DOMPurify.sanitize(data, { USE_PROFILES: { svg: true } });
    let $svg = $(cleanData);
    let $svg2 = $svg.clone();
    if (typeof imgID !== 'undefined') {
      $svg = $svg.attr('id', imgID);
      $svg2 = $svg2.attr('id', `${imgID}-clone`);
    }
    $svg = $svg.attr('class', `${imgClass} replaced-svg`);
    $svg = $svg.removeAttr('xmlns:a');
    $svg2 = $svg2.attr('class', `${imgClass} replaced-svg absolute -left-11`);
    $svg2 = $svg2.removeAttr('xmlns:a');
    if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
      $svg.attr('viewBox', `0 0 ${$svg.attr('height')} ${$svg.attr('width')}`);
    }
    if (!$svg2.attr('viewBox') && $svg2.attr('height') && $svg2.attr('width')) {
      $svg2.attr('viewBox', `0 0 ${$svg2.attr('height')} ${$svg2.attr('width')}`);
    }
    if ($svg.length) {
      $img.replaceWith($svg);
      if (isBtnAni) {
        $parentImage.append($svg2);
      }
    }
  }

  convertSvg() {
    if (this.$hasSvg.length) {
      this.$hasSvg.each((_index, e) => {
        const $img = $(e);
        const isBtnAni = $img.parents('.btn-ani').length > 0;
        const $parentImage = $img.parent();
        const imgURL = $img.attr('src');
        if (imgURL) {
          $.get(
            imgURL,
            (data) => {
              this.appendSvg(data, $img, isBtnAni, $parentImage );
            },
            'text',
          );
        }
      });
    }
  }
}

export default (() => {
  new ConvertSvg().convertSvg();
})();
