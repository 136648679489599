import DOMPurify from 'dompurify';

export default async function importModule(name, loader, styles) {
  const el = document.querySelectorAll(`[data-module="${name}"]`);
  const localStorageHasReloaded = 'hasReloaded';

  if (el.length === 0) {
    return Promise.resolve();
  }

  if (styles) {
    styles();
  }

  if (loader) {
    try {
      const module = await loader();
      return {
        module: module.default,
        el,
      };
    } catch (error) {
       /* eslint-disable */
      if (HONDAPS_HANDEL_FETCH_DYNAMIC_MODULES) {
        if (!localStorage.getItem(localStorageHasReloaded)) {
          localStorage.setItem(localStorageHasReloaded, 'true');
          window.location.reload();
        } else {
          $('body').removeClass('has-animation')
          const $warningPopup = $('.warning-popup');
          if ($warningPopup.length) {
            $warningPopup.appendTo('body').removeClass('hidden');
          }
          $('html').css('overflow', 'hidden');
          if (window.scrollAgent && window.scrollAgent.$scrollEvent) {
            window.scrollAgent.$scrollEvent.stop();
          }
          $('#btn-reload-page').click((e) => {
            e.preventDefault();
            location.reload();
          });
          localStorage.removeItem(localStorageHasReloaded);
          console.error(`Error loading module "${DOMPurify.sanitize(name)}":`, DOMPurify.sanitize(error));
        }
        return null;
      }
    }
  }
}
