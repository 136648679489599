import DOMPurify from 'dompurify';

const dangerousKeyword = [
  'javascript:', // eslint-disable-line
  'data:',
  'vbscript:',
  'file:',
  'ftp:',
  'tel:',
  'sms:',
  'mailto:',
  'chrome-extension:',
  'chrome-search:',
  'chrome-untrusted:',
  'chrome:',
  'about:',
  'blob:',
  'view-source:',
];

const isValidUrl = (url) => {
  try {
    const parsedUrl = new URL(url, window.location.origin);
    return (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') && parsedUrl.origin === window.location.origin;
  } catch (e) {
    return false;
  }
};

const replaceUrlWithValidValue = (url) => {
  const parsedUrl = new URL(url, window.location.origin);
  // get searchParams to validate the url, remove the param containing javascript
  const { searchParams } = parsedUrl;
  searchParams.forEach((value, key) => {
    let safeParamValue = value;
    // remove dangerous keyword with empty string
    dangerousKeyword.forEach((keyword) => {
      if (safeParamValue.includes(keyword)) {
        safeParamValue = safeParamValue.replace(keyword, '');
      }
    });

    // encode or decode param value to html entity
    safeParamValue = DOMPurify.sanitize(encodeURIComponent(safeParamValue));
    safeParamValue = DOMPurify.sanitize(decodeURIComponent(safeParamValue));
    // set the safe param value to searchParams
    searchParams.set(key, safeParamValue);
  });

  // return url with updated searchParams
  return decodeURIComponent(parsedUrl.toString());

};

const getPathNameAndSearchFromUrl = (url) => {
  const parsedUrl = new URL(url, window.location.origin);
  return `${parsedUrl.pathname}${parsedUrl.search}`;
};

const handleUrlXss = (url) => {
  let safeUrl = DOMPurify.sanitize(url);
  safeUrl = replaceUrlWithValidValue(safeUrl);
  if (isValidUrl(safeUrl)) {
    return safeUrl;
  }
  return '';
};

export default {
  handleUrlXss,
  getPathNameAndSearchFromUrl,
};