const preventCustomScrollPage = (elementRef) => {
  elementRef?.addEventListener('mouseenter', (e) => {
    e.stopPropagation();
    window.scrollAgent.$scrollEvent.stop();
  });
  elementRef?.addEventListener('mousemove', (e) => {
    e.stopPropagation();
    window.scrollAgent.$scrollEvent.stop();
  });
  elementRef?.addEventListener('mouseleave', (e) => {
    e.stopPropagation();
    window.scrollAgent.$scrollEvent.start();
  });
};

export default {
  preventCustomScrollPage,
};