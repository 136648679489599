export default class BrowserDetection {
  constructor() {
    this.$html = $('html');
  }

  browserDetection() {
    const isEdge = /Edge|Edg/.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
    const isSafari = navigator.userAgent.indexOf('Safari') > -1;
    const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
    if (isFirefox) {
      this.$html.addClass('firefox');
    }
    if (isChrome && isSafari && !isEdge) {
      this.$html.addClass('chrome');
    }
    if (!isChrome && isSafari) {
      this.$html.addClass('safari');
    }
    if (isEdge) {
      this.$html.addClass('edge');
    }
  }
}

new BrowserDetection().browserDetection();
