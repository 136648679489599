import { trackingControlUI } from '../utils';

export default class ModAccordion {
  constructor(el) {
    this.$el = $(el);

    this.classes = {
      jsExpand: 'js-expand',
      animating: 'animating',
      canCloseAll: 'can-close-all',
    };

    this.attributes = {
      ariaExpanded: 'aria-expanded',
    };

    this.tag = {
      buttonHasAttributeAriaExpanded: 'button[aria-expanded]',
    };

    this.elementClasses = {
      modAccordion: '.mod-accordion',
      onlyOpenOne: '.only-open-one',
      accordionItem: '.accordion-item',
    };
  }

  init() {
    if (this.$el.length) {
      this.$el.each((_index, element) => {
        this.addListeners($(element));
      });
    }
  }

  addListeners($element) {
    $element.find(this.tag.buttonHasAttributeAriaExpanded).each((_i, elem) => {
      $(elem).on('click', (e) => {
        if ($(e.currentTarget).is('.mouse')) {
          this.handleToggle(elem);
        }
      });

      $(elem).on('keydown', (e) => {
        this.keydownEventListener(e, elem);
      });

      const autoExpandUrl = $(elem).data('auto-expand-urls');
      this.autoExpand(autoExpandUrl, elem);
    });
  }

  autoExpand(autoExpandUrl = false, elem = null) {
    if (!autoExpandUrl || elem === null) {
      return;
    }
    const pageUrl = autoExpandUrl.split(', ');
    if (pageUrl.includes(window.location.pathname) && window.location.search === '' && !$(elem).hasClass('js-expand')) {
      this.handleToggle(elem);
    }
  }

  handleToggle(elem) {
    const $moduleParents = $(elem).parents(this.elementClasses.modAccordion);
    const $content = this.findContent($(elem));

    const open = $(elem).attr(this.attributes.ariaExpanded).trim() === 'true';
    const isOnlyOpenOne = $moduleParents.is(this.elementClasses.onlyOpenOne);
    const animationDuration = 400;

    if (isOnlyOpenOne) {
      this.handleCaseOnlyOpenOneAccordionItem(elem, $moduleParents, animationDuration, open, $content);
    } else {
      if ($(elem).hasClass(this.classes.animating)) {
        return;
      }
      if (!open) {
        $content.slideDown(animationDuration);
        trackingControlUI($(elem), $(elem).attr('data-event-label-state1'));
      } else {
        $content.slideUp({
          duration: animationDuration,
          easing: 'linear',
          step: (_now) => {
            window.scroll.update();
          },
        }).removeClass('hidden');
        trackingControlUI($(elem), $(elem).attr('data-event-label-state2'));
      }
      $(elem).addClass(this.classes.animating).attr(this.attributes.ariaExpanded, `${!open}`).toggleClass(this.classes.jsExpand).parents('.accordion-item').toggleClass('js-open');

      // prevent multiple click events
      setTimeout(() => {
        $(elem).removeClass(this.classes.animating);
      }, animationDuration + 100);
    }
  }

  handleCaseOnlyOpenOneAccordionItem(elem, $moduleParents, animationDuration, open, $content) {
    // Mod Faq
    if (this.$el.hasClass(this.classes.canCloseAll)) {
      if ($(elem).hasClass(this.classes.animating)) {
        return;
      }
      this.deActivate($moduleParents);

      $(elem).addClass(this.classes.animating);

      // prevent multiple click events
      setTimeout(() => {
        $(elem).removeClass(this.classes.animating);
      }, animationDuration + 100);
    }

    if (!open) {
      // false
      if (!this.$el.hasClass(this.classes.canCloseAll)) {
        this.deActivate($moduleParents);
      }
      $(elem).attr(this.attributes.ariaExpanded, true).addClass(this.classes.jsExpand);
      $(elem).parents(this.elementClasses.accordionItem).addClass(this.classes.jsExpand);
      $content.slideDown();
    }
  }

  deActivate(element) {
    const $buttons = element.find(this.tag.buttonHasAttributeAriaExpanded);

    $buttons.each((_i, elem) => {
      const $elem = $(elem);
      const contentEl = this.findContent($elem);

      $elem.attr(this.attributes.ariaExpanded, false).removeClass(this.classes.jsExpand);
      $elem.closest(this.elementClasses.accordionItem).removeClass(this.classes.jsExpand);
      contentEl.slideUp({
        step: (_now) => {
          window.scroll.update();
        },
      });
    });
  }

  findContent(btn) {
    const controlsId = btn.attr('aria-controls');
    return $(`#${controlsId}`);
  }

  keydownEventListener(event, element) {
    const key = event.keyCode;

    switch (key) {
      case 32:
        event.preventDefault();
        this.handleToggle(element);
        break;

      case 13:
        event.preventDefault();
        this.handleToggle(element);
        break;

      default:
        break;
    }
  }
}

const $modAccordion = $('.mod-accordion');
if ($modAccordion.length) {
  $modAccordion.each((_i, e) => {
    new ModAccordion(e).init();
  });
}