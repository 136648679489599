import DOMPurify from 'dompurify';
import {
  isDesktop,
  getYoutubeVideoId,
  getVimeoVideoId,
  getKalturaVideoId,
  getPosition,
  uuidv4,
  trackingControlUI,
} from '../utils';

export default class BackgroundVideo {
  constructor(el, $timer = null, mySwiper = null) {
    this.$el = $(el);
    this.$timer = $timer;
    this.mySwiper = mySwiper;
    this.classes = {
      pause: 'pause',
      pauseVideo: 'pause-video',
      isClickPause: 'is-click-pause',
      imgPlaceholderVideo: 'img-placeholder-video',
      textControl: 'text-control',
      activeSound: 'active-sound',
      opacityZero: 'opacity-0',
      jsVideoSwiper: 'js-video-swiper',
      jsVideoFirstPause: 'js-video-first-pause',
      isLoadedVideo: 'is-loaded-video',
      modRacing: 'mod-racing',
      modBuilderPreview: 'mod-builder-preview',
      endVideo: 'end-video',
      playThenPause: 'play-then-pause',
      swiperSlideActive: 'swiper-slide-active',
      opacityZeroIframe: 'opacity-zero-iframe',
      seekToZero: 'seek-to-zero',
      onPlayFalse: 'on-play-false',
      onPauseTrue: 'on-pause-true',
    };

    this.elementClasses = {
      iframeVideo: '.js-iframe-video',
      containerControlVideo: '.container-control-video',
      timerBulletInner: '.timer-bullet-inner',
      buttonPlayVideoMB: '.button-play-video-mb',
    };

    this.type = null;
    this.id = null;
    this.url = null;
    this.player = null;
    this.playerKaltura = null;
    this.offsetTopModule = getPosition(this.$el.get(0)).y;
    this.heightWindow = $(window).innerHeight();
    this.heightModule = this.$el.innerHeight();
    this.listVideo = [];
    this.isLoadedVideo = false;
    this.isLoadedLazyLoadVideo = false;
    this.isSetPlayerVideo = false;
    this.interval = null;
    this.nextSlide = true;
    this.onPlay = true;
    this.positionFlag = 0;
    this.dom = {
      $iframeVideo: this.$el.find(this.elementClasses.iframeVideo),
      $buttonPlayPause: this.$el.find('.btn-play-pause'),
      $buttonControlSound: this.$el.find('.btn-control-sound'),
      $swiperSlide: this.$el.parents('.swiper-slide'),
      $opacityZeroIframe: this.$el.find(`.${this.classes.opacityZeroIframe}`),
    };

    this.boolean = {
      isVideoGallery: this.$el.hasClass('js-video-gallery'),
      isNoSoundVideo: this.$el.hasClass('no-sound-video'),
    };
  }

  init() {
    if (this.dom.$iframeVideo.length) {
      this.dom.$iframeVideo.attr('id', uuidv4());
      this.validateSrc();
      if (this.type !== null) {
        this.lazyLoadVideo(0);
        this.eventOnScrollWindow();
        this.addListeners();
      }
    }
    return this.player;
  }

  addListeners() {
    if (this.dom.$buttonPlayPause.length > 0) {
      this.dom.$buttonPlayPause.on('click', (event) => {
        this.eventClickButtonPlayPauseVideo(event);
      });
    }

    if (this.dom.$buttonControlSound.length > 0 && !this.boolean.isVideoGallery) {
      this.dom.$buttonControlSound.on('click', (event) => {
        this.eventClickButtonSoundVideo(event);
      });
    }
  }

  validateSrc() {
    let src = this.dom.$iframeVideo.attr('data-src');
    if (this.dom.$iframeVideo.attr('data-src-mb')) {
      this.dom.$iframeVideo.addClass('mobile-style');
      if (window.innerWidth > 767) {
        src = this.dom.$iframeVideo.attr('data-src');
      } else {
        src = this.dom.$iframeVideo.attr('data-src-mb');
      }
    }
    const youtubeVideoId = getYoutubeVideoId(src);
    const vimeoVideoId = getVimeoVideoId(src);
    const kalturaVideoId = getKalturaVideoId(src);

    if (youtubeVideoId) {
      this.type = 'youtube';
      this.id = youtubeVideoId;
    } else if (vimeoVideoId) {
      this.type = 'vimeo';
      this.url = src;
    } else if (kalturaVideoId){
      this.type = 'kaltura';
      this.id = kalturaVideoId;
    } else {
      // do nothing
    }
  }

  isYouTubeAPIReady() {
    return typeof window.YT !== 'undefined' && typeof window.YT.Player !== 'undefined';
  }

  isVimeoAPIReady() {
    return typeof window.Vimeo !== 'undefined';
  }

  lazyLoadVideo(scrollBottomWindow) {
    const intervalTime = setInterval(() => {
      if ((this.isYouTubeAPIReady() || this.isVimeoAPIReady() || typeof KalturaPlayer !== 'undefined') && this.isLoadedLazyLoadVideo === false) {
        clearInterval(intervalTime);
        this.setPlayerVideo(scrollBottomWindow);
      }
    }, 2000);
  }

  setPlayerVideo(scrollBottomWindow) {
    if (scrollBottomWindow >= this.offsetTopModule - this.heightWindow && this.player === null) {
      if (this.type === 'youtube' && this.isYouTubeAPIReady()) {
        this.setPlayerYoutube();
      } else if (this.type === 'vimeo' && this.isVimeoAPIReady()) {
        this.setPlayerVimeo();
      } else if (this.type === 'kaltura' && typeof KalturaPlayer !== 'undefined') {
        this.setPlayerKaltura();
      } else {
        // do nothing
      }
    }
  }

  getActiveSlide() {
    let isActive = true;
    if (this.dom.$swiperSlide.length) {
      isActive = this.dom.$swiperSlide.hasClass(this.classes.swiperSlideActive);
    }
    if (this.$el.hasClass('swiper-slide')) {
      isActive = this.$el.hasClass(this.classes.swiperSlideActive);
    }
    return isActive;
  }

  setPlayerKaltura() {
    /* eslint-disable */
    try {
      this.player = window.KalturaPlayer.setup({
        targetId: this.dom.$iframeVideo.attr('id'),
        logLevel: 'OFF',
        plugins: true,
        playback: {
          autoplay: true,
          muted: true,
          loop: true,
          playsinline: true,
          allowMutedAutoPlay: true,
        },
        provider: {
          partnerId: window.HONDA_KALTURA_SETTINGS.partnerId,
          uiConfId: window.HONDA_KALTURA_SETTINGS.playerId
        },
      });
      this.player.loadMedia({entryId: this.id});
      this.player.addEventListener(this.player.Event.Core.MEDIA_LOADED, _event => {
        this.dom.$iframeVideo.addClass('kaltura-video-open');
        this.dom.$opacityZeroIframe.removeClass(this.classes.opacityZero);
        if ($(`#${this.dom.$iframeVideo.attr('id')}`).parents('.mod-model-hero-banner, .mod-racing-profile-banner').length > 0) {
          $(`#${this.dom.$iframeVideo.attr('id')}`).removeClass(this.classes.opacityZero);
        }
        $(`#${this.dom.$iframeVideo.attr('id')}`).parents('.racing-video').removeClass(this.classes.opacityZero);
        this.$el.find(this.elementClasses.containerControlVideo).removeClass(this.classes.opacityZero);
        this.isLoadedLazyLoadVideo = true;

      });

      this.player.addEventListener(this.player.Event.Core.DURATION_CHANGE, _event => {
        this.updateTimer();
      });

      this.player.addEventListener(this.player.Event.Core.PLAY, _event => {
        $(`#${this.dom.$iframeVideo.attr('id')}`).parents('.item-gallery-item').find(this.elementClasses.buttonPlayVideoMB).addClass('opacity-0');
      });
      this.player.addEventListener(this.player.Event.Core.PAUSE, _event => {
        $(`#${this.dom.$iframeVideo.attr('id')}`).parents('.item-gallery-item').find(this.elementClasses.buttonPlayVideoMB).removeClass('opacity-0');
      });

      this.player.addEventListener(this.player.Event.Core.ENDED, _event => {
        this.$timer.find(this.elementClasses.timerBulletInner).css('width', ``);
        this.mySwiper.slideNext();
      });
    } catch (error) {
      console.log('Error: ', DOMPurify.sanitize(error));
    }
    /* eslint-enable */
  }

  setPlayerYoutube() {
    const isActiveSlide = this.getActiveSlide();
    let isAutoPlay = isActiveSlide ? 1 : 0;
    const isLoopVideo = this.$el.hasClass(this.classes.modRacing) ? 0 : 1;
    if (this.$el.hasClass(this.classes.jsVideoSwiper)) {
      isAutoPlay = 1;
    }

    if (this.$el.hasClass(this.classes.jsVideoFirstPause)) {
      isAutoPlay = 0;
    }
    this.onPlay = isAutoPlay;
    this.player = new window.YT.Player(this.dom.$iframeVideo[0], {
      height: '',
      width: '',
      videoId: this.id,
      playerVars: {
        autoplay: isAutoPlay,
        loop: isLoopVideo,
        mute: 1,
        controls: 0,
        rel: 0,
        playlist: this.id,
        modestbranding: 0,
        showinfo: 0,
        autohide: 1,
      },
      events: {
        onReady: () => {
          $(this.player.g).attr('tabindex', '-1').removeAttr('frameborder');
          this.$el.addClass(this.classes.isLoadedVideo);
        },
        onStateChange: (e) => {
          if (e.data === window.YT.PlayerState.PLAYING && this.getActiveSlide()) {
            this.updateTimer();
          }
          if (e.data === window.YT.PlayerState.UNSTARTED && this.nextSlide) {
            this.nextSlide = false;
            this.nextSliderAfterEndVideo();
          }
          setTimeout(() => {
            this.nextSlide = true;
          }, 100);
          this.caseOnchangeStateVideo(e);
          this.checkVideoPauseOrPlay(e);
        },

      },
    });
  }

  checkVideoPauseOrPlay(event) {
    if (event.data === window.YT.PlayerState.PLAYING) {
      this.$el.find(this.elementClasses.buttonPlayVideoMB).addClass('opacity-0');
    } else if (event.data === window.YT.PlayerState.PAUSED) {
      this.$el.find(this.elementClasses.buttonPlayVideoMB).removeClass('opacity-0');
    } else {
      // do nothing
    }
  }

  nextSliderAfterEndVideo() {
    if (this.mySwiper && this.$timer) {
      this.$timer.find(this.elementClasses.timerBulletInner).css('width', ``);
      this.mySwiper.slideNext();
    }
  }

  updateTimer() {
    if (!this.$timer || !(this.player || this.playerKaltura)) {
      return;
    }
    if (this.animationId) {
      cancelAnimationFrame(this.animationId);
    }

    let lastUpdateTime = performance.now();
    const updateInterval = 10;

    const update = (currentTime, duration, getPlayerTime) => {
      if (currentTime - lastUpdateTime > updateInterval) {
        getPlayerTime().then((playerTime) => {
          const percentage = (playerTime / duration) * 100;
          this.$timer.find(this.elementClasses.timerBulletInner).css('width', `${percentage}%`);
          lastUpdateTime = currentTime;
        });
      }
      this.animationId = requestAnimationFrame((time) => update(time, duration, getPlayerTime));
    };
    if (this.type === 'kaltura') {
      const playerIdT = $(this.playerKaltura).attr('id');
      const that = this;
      window.kWidget.addReadyCallback((playerId) => {
        if (playerId === playerIdT) {
          const kdp = document.getElementById(playerIdT);
          const duration = kdp.evaluate('{duration}');
          const getPlayerTime = () => Promise.resolve(kdp.evaluate('{video.player.currentTime}'));
          that.animationId = requestAnimationFrame((time) => update(time, duration, getPlayerTime));
        }
      });
    } else if (this.type === 'youtube') {
      const duration = this.player.getDuration();
      const getPlayerTime = () => Promise.resolve(this.player.getCurrentTime());
      this.animationId = requestAnimationFrame((time) => update(time, duration, getPlayerTime));
    } else {
      this.player.getDuration().then((duration) => {
        const getPlayerTime = () => this.player.getCurrentTime();
        this.animationId = requestAnimationFrame((time) => update(time, duration, getPlayerTime));
      });
    }
  };

  caseOnchangeStateVideo(e) {
    if (e.data > 0) {
      this.$el.find(this.elementClasses.iframeVideo).removeClass('opacity-0');
    }
    if (e.data === window.YT.PlayerState.PLAYING) {
      this.$el.find(`.${this.classes.imgPlaceholderVideo}`).addClass(this.classes.opacityZero).end().find(this.elementClasses.containerControlVideo).removeClass(this.classes.opacityZero);
      this.dom.$opacityZeroIframe.removeClass(this.classes.opacityZero);
      this.caseOnchangeChildStateVideo(e);
      this.isLoadedLazyLoadVideo = true;
    }
  }

  caseOnchangeChildStateVideo() {
    // Mod video swiper
    if (this.$el.hasClass(this.classes.jsVideoSwiper) && this.isLoadedVideo === false) {
      this.isLoadedVideo = true;
      this.dom.$buttonPlayPause.addClass(this.classes.isLoadedVideo);
    }

    // Mod racing and builder preview
    if (this.$el.hasClass(this.classes.modRacing) || this.$el.hasClass(this.classes.seekToZero)) {
      this.eventPauseVideoBeforeOneSecondOnYoutube();
    }

    // Mod three card
    if (this.$el.hasClass(this.classes.playThenPause) && isDesktop() && this.isLoadedVideo === false) {
      this.player.pauseVideo();
      this.isLoadedVideo = true;
    }
  }

  setPlayerVimeo() {
    let isAutoPlay = this.getActiveSlide();
    let isLoopVideo = true;
    if (this.$el.hasClass(this.classes.modRacing)) {
      isLoopVideo = false;
    }
    if (this.$el.hasClass(this.classes.jsVideoSwiper)) {
      isAutoPlay = true;
    }

    if (this.$el.hasClass(this.classes.jsVideoFirstPause)) {
      isAutoPlay = 0;
    }
    this.player = new window.Vimeo.Player(this.dom.$iframeVideo[0], {
      url: this.url,
      autoplay: isAutoPlay,
      loop: isLoopVideo,
      muted: true,
      controls: false,
      background: true,
    });

    this.player.ready().then(() => {
      $(this.player.element).attr('tabindex', '-1').removeAttr('frameborder').removeAttr('width').removeAttr('height');;
      this.$el.addClass(this.classes.isLoadedVideo);
      this.updateTimer();
    });
    this.player.on('seeked', () => {
      this.nextSlide = false;
      this.nextSliderAfterEndVideo();
    });
    this.player.on('play', () => {
      this.onPlay = true;
      this.$el.find(`.${this.classes.imgPlaceholderVideo}`).addClass(this.classes.opacityZero).end().find(this.elementClasses.containerControlVideo).removeClass(this.classes.opacityZero).end().find(this.elementClasses.iframeVideo).removeClass(this.classes.opacityZero);
      this.dom.$opacityZeroIframe.removeClass(this.classes.opacityZero);
      // Mod video swiper
      if (this.$el.hasClass(this.classes.jsVideoSwiper) && this.isLoadedVideo === false) {
        this.isLoadedVideo = true;
        this.dom.$buttonPlayPause.addClass(this.classes.isLoadedVideo);
      }

      // Mod racing and builder preview
      if (this.$el.hasClass(this.classes.modRacing) || this.$el.hasClass(this.classes.seekToZero)) {
        this.eventPauseVideoBeforeOneSecondOnVimeo();
      }

      // Mod three cards
      if (this.$el.hasClass(this.classes.playThenPause) && isDesktop() && this.isLoadedVideo === false) {
        this.player.pause();
        this.isLoadedVideo = true;
      }
      this.updateTimer();
      this.onPlayVimeo = true;
      this.isLoadedLazyLoadVideo = true;
    });
  }

  eventPauseVideoBeforeOneSecondOnYoutube() {
    const intervalTimeVideoYoutube = setInterval(() => {
      const currentTimePlayer = this.player.getCurrentTime();
      const durationPlayer = this.player.getDuration();

      if (currentTimePlayer >= durationPlayer - 1.5) {
        const isModRacing = this.$el.hasClass(this.classes.modRacing);
        const isSeekToZero = this.$el.hasClass(this.classes.seekToZero);

        if (isModRacing) {
          this.pauseVideo();
          this.$el.addClass(this.classes.endVideo);
        }

        if (isSeekToZero) {
          this.player.seekTo(0);
        }

        clearInterval(intervalTimeVideoYoutube);
      }
    }, 500);
  }

  eventPauseVideoBeforeOneSecondOnVimeo() {
    const intervalTimeVideoVimeo = setInterval(() => {
      this.player.getCurrentTime().then((seconds) => {
        this.player.getDuration().then((duration) => {
          if (seconds >= duration - 1.5) {
            const isModRacing = this.$el.hasClass(this.classes.modRacing);
            const isSeekToZero = this.$el.hasClass(this.classes.seekToZero);

            if (isModRacing) {
              this.pauseVideo();
              this.$el.addClass(this.classes.endVideo);
            }

            if (isSeekToZero) {
              this.player.setCurrentTime(0);
            }

            clearInterval(intervalTimeVideoVimeo);
          }
        });
      });
    }, 1000);
  }

  eventClickButtonPlayPauseVideo(event) {
    const $elm = $(event.currentTarget);
    $elm.addClass('pointer-events-none');
    if ($elm.hasClass(this.classes.pause)) {
      this.playVideo();
      $elm.removeClass(this.classes.isClickPause);
      this.$el.removeClass(this.classes.pauseVideo);
      trackingControlUI($elm, 'play video');
    } else {
      this.onPlay = true;
      this.pauseVideo();
      $elm.addClass(this.classes.isClickPause);
      this.$el.addClass(this.classes.pauseVideo);
      trackingControlUI($elm, 'pause video');
    }
    setTimeout(() => {
      $elm.removeClass('pointer-events-none');
    }, 500);
  }

  eventClickButtonSoundVideo(event) {
    const $elm = $(event.currentTarget);
    if ($elm.hasClass(this.classes.activeSound)) {
      this.muteVideo();
      trackingControlUI($elm, 'mute video');
    } else {
      this.unMuteVideo();
      trackingControlUI($elm, 'unmute video');
    }
  }

  eventOnScrollWindow() {
    window.scrollAgent.scrollEvent((args) => {
      const scrollY = parseFloat(args.scroll.y.toFixed(2));
      if (this.positionFlag !== scrollY) {
        this.positionFlag = scrollY;
        this.heightWindow = $(window).innerHeight();
        this.offsetTopModule = getPosition(this.$el.get(0)).y;
        this.heightModule = this.$el.innerHeight();
        const scrollBottomWindow = window.customScrollY + this.heightWindow;
        const offsetBottomModule = this.offsetTopModule + this.heightModule;
        this.lazyLoadVideo(scrollBottomWindow);
        if ( (this.player !== null || this.playerKaltura !== null ) && this.isLoadedLazyLoadVideo === true && !this.$el.hasClass('many-video-in-screen')) {
          this.eventPlayOrPauseVideoWhenOnScroll(scrollBottomWindow, offsetBottomModule);
        }
      }
    });
  }

  eventPlayOrPauseVideoWhenOnScroll(scrollBottomWindow, offsetBottomModule) {
    let isScrollBottomSmallerOffsetTopModule = scrollBottomWindow < this.offsetTopModule;
    if (this.$el.hasClass(this.classes.modRacing) && isDesktop()) {
      isScrollBottomSmallerOffsetTopModule = scrollBottomWindow < this.offsetTopModule + (this.heightModule * 0.70);
    }
    if (isScrollBottomSmallerOffsetTopModule || window.customScrollY > offsetBottomModule) {
      this.casePauseScrollVideo();
      this.pauseVideo();
    } else if (!this.dom.$buttonPlayPause.hasClass(this.classes.isClickPause) && !this.$el.hasClass('no-in-screen')) {
      this.casePlayScrollVideo();
    } else {
      // do nothing
    }
  }

  casePauseScrollVideo() {
    if (this.dom.$swiperSlide.length && this.dom.$swiperSlide.hasClass(this.classes.swiperSlideActive) && !this.$el.hasClass(this.classes.jsVideoSwiper)) {
      return;
    }
    this.pauseVideo();
  }

  casePlayScrollVideo() {
    if (this.$el.hasClass(this.classes.playThenPause) && isDesktop()) {
      return;
    }
    if (this.mySwiper && this.mySwiper.realIndex !== this.$el.parents('.swiper-slide').index()) {
      return;
    }
    this.playVideo();
  }

  muteVideo() {
    if (this.type === 'youtube' && this.player.mute) {
      this.player.mute();
    } else if (this.type === 'vimeo' && this.player) {
      this.player.setVolume(0);
    } else if (this.type === 'kaltura') {
      this.player.muted = true;
    } else {
      // do nothing
    }

    window.mutedVideo = false;
    this.dom.$buttonControlSound.removeClass(this.classes.activeSound).attr('aria-label', ' Turn off the video sound');
  }

  unMuteVideo() {
    if (this.player) {
      if (this.type === 'youtube' && this.player.unMute) {
        this.player.unMute();
        setTimeout(() => {
          this.player.setVolume(100);
        }, 10);
      } else if (this.type === 'vimeo') {
        this.player.setVolume(1);
      } else if (this.type === 'kaltura') {
        this.player.muted = false;
      } else {
        // do nothing
      }
    }

    window.mutedVideo = true;
    this.dom.$buttonControlSound.addClass(this.classes.activeSound).attr('aria-label', ' Turn on the video sound');
  }

  pauseVideo() {
    clearInterval(this.interval);
    if (this.$el.hasClass(this.classes.onPauseTrue)) {
      this.onPlay = true;
    }
    if (!this.onPlay) { return; }
    this.onPlay = false;
    this.pauseVideoWithCaseYoutubeAndVimeo();

    if (window.mutedVideo === false && isDesktop() && !this.boolean.isNoSoundVideo) {
      this.muteVideo();
    }

    if (!this.dom.$buttonPlayPause.hasClass(this.classes.pause)) {
      // force get variable videoPauseText from global
      /* eslint-disable-next-line */
      this.dom.$buttonPlayPause.addClass(this.classes.pause).find(`.${this.classes.textControl}`).text(!window.videoPlayText ? 'Play' : window.videoPlayText);
    }
  }

  pauseVideoWithCaseYoutubeAndVimeo() {
    if (this.player) {
      if (this.type === 'youtube' && this.player.pauseVideo) {
        this.player.pauseVideo();
      } else {
        this.player.pause();
      }
    }
  }

  isRacingAndEndVideo() {
    return this.$el.hasClass(this.classes.modRacing) && this.$el.hasClass(this.classes.endVideo);
  }

  playVideo() {
    if (this.$el.hasClass(this.classes.onPlayFalse)) {
      this.onPlay = false;
    }
    if (this.onPlay) { return; }
    this.onPlay = true;
    const isRacingAndEndVideo = this.isRacingAndEndVideo();

    this.playVideoWithCaseYoutubeAndVimeo(isRacingAndEndVideo);

    if (window.mutedVideo === true && isDesktop() && !this.boolean.isNoSoundVideo && this.dom.$buttonControlSound.length > 0) {
      this.unMuteVideo();
    }
    this.$el.removeClass(this.classes.endVideo);
    this.CheckTextControlVideo();
  }

  playVideoWithCaseYoutubeAndVimeo(isRacingAndEndVideo) {
    if (this.player) {
      if (this.type === 'youtube' && this.player.playVideo) {
        if (isRacingAndEndVideo) {
          this.player.seekTo(0);
        }
        this.player.playVideo();
      } else {
        if (isRacingAndEndVideo) {
          this.player.setCurrentTime(0);
        }
        this.player.play();
      }
    }
  }

  CheckTextControlVideo() {
    if (this.dom.$buttonPlayPause.hasClass(this.classes.pause)) {
      // force get variable videoPauseText from global
      /* eslint-disable-next-line */
      this.dom.$buttonPlayPause.removeClass(this.classes.pause).find(`.${this.classes.textControl}`).text(!window.videoPauseText ? 'Pause' : window.videoPauseText);
    }
  }

}

const $backgroundVideo = $('.js-background-video');
if ($backgroundVideo.length) {
  $backgroundVideo.each((_i, e) => {
    new BackgroundVideo(e).init();
  });
}
