import { getPosition, isDesktop } from '../utils';

export default class HoverLoopImage {
  constructor(el) {
    this.$el = $(el);
    this.scrollTopWindow = 0;
    this.offsetTopModule = getPosition(this.$el.get(0)).y;
    this.heightWindow = $(window).innerHeight();
    this.isHovering = false;
    this.isStartAnimation = false;
    this.intervalTime = null;
    this.positionFlag = 0;
    this.attribute = {
      opacity: 'opacity',
    };

    this.tag = {
      image: 'img',
    };

    this.dom = {
      $listImage: this.$el.find('img'),
    };
  }

  init() {
    if (this.dom.$listImage.length > 1) {
      this.lazyActionChangeImageWithOpacityEffect(this.heightWindow);
      this.addListeners();
    }
  }

  addListeners() {
    if (isDesktop()) {
      this.$el.on({
        mouseenter: (e) => {
          this.actionChangeImageWithOpacityEffect($(e.currentTarget));
        },
        mouseleave: () => {
          this.eventMouseLeaveChangeImage();
        },
      });
    } else {
      window.scrollAgent.scrollEvent((args) => {
        const scrollY = parseFloat(args.scroll.y.toFixed(2));
        if (this.positionFlag !== scrollY) {
          this.positionFlag = scrollY;
          this.scrollTopWindow = scrollY;
          this.heightWindow = $(window).innerHeight();
          this.offsetTopModule = getPosition(this.$el.get(0)).y;
          const scrollBottomWindow = this.scrollTopWindow + this.heightWindow;
          this.lazyActionChangeImageWithOpacityEffect(scrollBottomWindow);
        }
      });
    }
  }

  eventMouseLeaveChangeImage() {
    clearInterval(this.intervalTime);
    const $listImageHoverOpacity = this.$el.find(this.tag.image);
    $listImageHoverOpacity.css('transition', ' all .3s ease-out');
    $listImageHoverOpacity.css(this.attribute.opacity, '0');

    this.$el.each((_index, elm) => {
      $(elm).find(this.tag.image).first().css(this.attribute.opacity, '1');
    });

    this.isHovering = false;
  }

  lazyActionChangeImageWithOpacityEffect(scrollBottomWindow) {
    if (!isDesktop() && !this.isStartAnimation && scrollBottomWindow > this.offsetTopModule) {
      this.isStartAnimation = true;
      this.$el.each((_index, elm) => {
        this.actionChangeImageWithOpacityEffect($(elm));
      });
    }
  }

  actionChangeImageWithOpacityEffect($elm) {
    const $listImageHoverOpacity = $elm.find(this.tag.image);
    const lengthItem = $listImageHoverOpacity.length;
    let imageIndex = 0;

    $listImageHoverOpacity.removeAttr('style');

    clearInterval(this.intervalTime);
    if (!this.isHovering) {
      imageIndex = 1;
      this.isHovering = true;

      $listImageHoverOpacity.css(this.attribute.opacity, '0').eq(imageIndex).css(this.attribute.opacity, '1');
    }

    this.intervalTime = setInterval(() => {
      imageIndex = (imageIndex + 1) % lengthItem;
      $listImageHoverOpacity.css(this.attribute.opacity, '0');

      $listImageHoverOpacity.eq(imageIndex).css(this.attribute.opacity, '1');
    }, 2000);
  }
}

const $hoverLoopImage = $('.js-hover-loop');
if ($hoverLoopImage.length) {
  $hoverLoopImage.each((_i, e) => {
    new HoverLoopImage(e).init();
  });
}
