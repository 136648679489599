const VERSION_FULL_PRICE = 'full-price';
export default class Tooltip {
  constructor() {
    this.$tooltips = $('.js-tooltip');
    this.classes = {
      show: 'show',
      tooltip: 'tooltip',
    };
    this.elementClasses = {
      tooltip: '.tooltip',
      wrapTooltip: '.wrap-tooltip',
    };
  }

  tooltipHTML(content) {
    return `
      <span class="wrap-tooltip z-99 whitespace-normal">
        <span class="tooltip-card block">
          <button type="button" class="tooltip-close-btn">
          <span class="sr-only">Close Tooltip</span>
            <span class="icomoon icon-x" aria-hidden="true"></span>
          </button>
          <span class="tooltip-content block">${content}</span>
        </span>
      </span>
    `;
  }

  tooltipHTMLFullPriceVersion(data) {
    const { destinationChargeText, destinationChargePrice, freightSurchargeText, freightSurchargePrice, tooltipContent } = data;
    return `
    <div class="wrap-tooltip z-99 whitespace-normal">
      <div class="tooltip-card rounded-4">
        <button type="button" class="tooltip-close-btn text-black">
          <span class="sr-only">Close Tooltip</span>
          <span class="icomoon icon-x" aria-hidden="true"></span>
        </button>
        <div class="tooltip-content leading-normal">
          ${destinationChargePrice ? `<p class="${freightSurchargePrice ? 'mb-0' : 'mb-4'}"><span>${destinationChargeText || ''}:</span> ${destinationChargePrice}</p>` : ''}
          ${freightSurchargePrice ? `<p class="mb-4"><span>${freightSurchargeText || ''}:</span> ${freightSurchargePrice}</p>` : ''}
          ${tooltipContent ? `<p class="mb-0">${tooltipContent}</p>` : ''}
        </div>
      </div>
    </div>
    `;
  }

  openTooltipEvent(tooltipEl) {
    const $tooltipEl = $(tooltipEl);
    const tooltipText = $tooltipEl.data('tooltip-text');
    const destinationChargeText = $tooltipEl.data('destination-charge-text');
    const destinationChargePrice = $tooltipEl.data('destination-charge-price');
    const freightSurchargeText = $tooltipEl.data('freight-surcharge-text');
    const freightSurchargePrice = $tooltipEl.data('freight-surcharge-price');
    const htmlVersion = $tooltipEl.data('version');
    const data = { destinationChargeText, destinationChargePrice, freightSurchargeText, freightSurchargePrice, tooltipContent: tooltipText };
    const html = htmlVersion === VERSION_FULL_PRICE ? this.tooltipHTMLFullPriceVersion(data) : this.tooltipHTML(tooltipText);

    // check if tooltip has been appended
    if (!$tooltipEl.find(this.elementClasses.wrapTooltip).length) {
      $tooltipEl.append(html);
    }

    // condition for show tooltip
    if (htmlVersion === VERSION_FULL_PRICE || htmlVersion !== VERSION_FULL_PRICE && tooltipText) {
      $tooltipEl.find(this.elementClasses.wrapTooltip).addClass(this.classes.show);
    }
  }

  closeTooltipAction($tooltipEl) {
    $tooltipEl.find(this.elementClasses.wrapTooltip).removeClass(this.classes.show);
  }

  clickOutsideTooltipEvent($target) {
    this.$tooltips.each((_i, tooltipEl) => {
      const $tooltipEl = $(tooltipEl);
      if (!$tooltipEl.is($target) && !$tooltipEl.has($target).length) {
        this.closeTooltipAction($tooltipEl);
      }
    });
  }

  closeAllTooltip($excludeElm = null) {
    this.$tooltips.each((_i, elm) => {
      const $tooltipEl = $(elm);
      if ($tooltipEl && !$tooltipEl.is($excludeElm) && $tooltipEl.find(this.elementClasses.wrapTooltip).hasClass(this.classes.show)) {
        this.closeTooltipAction($tooltipEl);
      }
    });
  }

  ADA() {
    $(window).on('keydown', (event) => {
      if (event.keyCode === 27) {
        this.closeAllTooltip();
      }
    });
  }

  addListeners() {
    this.ADA();
    $(window).on('resize', () => {
      this.closeAllTooltip();
    });

    // close tooltip when click outside
    $(document).on('click', (event) => {
      const $target = $(event.target);
      this.clickOutsideTooltipEvent($target);
    });

    // close tooltip when click close button
    $(document).on('click', '.tooltip-close-btn', (event) => {
      const $tooltipEl = $(event.target).closest(this.elementClasses.tooltip);
      this.closeTooltipAction($tooltipEl);
    });

    // open tooltip
    this.$tooltips.on('click', (event) => {
      const tooltipEl = event.currentTarget;
      if ($(tooltipEl).find(this.elementClasses.wrapTooltip).hasClass(this.classes.show)) {
        this.closeTooltipAction($(tooltipEl));
      } else {
        this.openTooltipEvent(tooltipEl);
      }
    });
  }

  init() {
    this.addListeners();
  }
}

new Tooltip().init();
