import './Menu';
import './ADAMenu';
import { isDesktop } from '../../utils';
import { PIN_HEADER_EVENT, MOUSE_WHEEL_CUSTOM_EVENT } from '../../constants';

export default class Header {
  constructor() {
    this.attributes = {
      dataScrollY: 'data-scroll-y',
    };

    this.classes = {
      pinHeader: 'pin-header',
      isOpenMenu: 'is-open-menu',
      wheelDown: 'wheel-down',
      wheelUp: 'wheel-up',
      hide: 'hide-header',
      actionOpenMenuMb: 'is-action-open-menu',
    };

    this.tags = {
      focusItem: 'input, a, button',
    };

    this.dom = {
      $html: $('html'),
      $body: $('body'),
      $header: $('#header'),
      $form: $('#header .form-search'),
      $popupSearch: $('.popup-search'),
    };

    this.$topHeader = this.dom.$header.find('.header-top');
    this.positionFlag = 0;
    this.numberScroll = 16;
  }

  init() {
    if (this.dom.$header.length) {
      this.wheelUpEvent = new CustomEvent('wheelUpCustom');
      this.wheelDownEvent = new CustomEvent('wheelDownCustom');
      this.wheelUpEvent2 = new CustomEvent(MOUSE_WHEEL_CUSTOM_EVENT, { detail: { wheel: 'up' } });
      this.wheelDownEvent2 = new CustomEvent(MOUSE_WHEEL_CUSTOM_EVENT, { detail: { wheel: 'down' } });
      this.pinHeaderEvent = new CustomEvent(PIN_HEADER_EVENT, { detail: { pinHeader: true } });
      this.unPinHeaderEvent = new CustomEvent(PIN_HEADER_EVENT, { detail: { pinHeader: false } });
      window.showMainNav = true;
      this.settingPin();
      this.setVariablesCssHeader();
      this.addEventListeners();
      this.checkSubmitFormSearch();
    }
  }

  addEventListeners() {
    window.scrollAgent.scrollEvent((args) => {
      if (isDesktop()) {
        this.windowScrollTop = parseFloat(args.scroll.y.toFixed(2));
        if (!this.dom.$html.hasClass(this.classes.isOpenMenu) && (this.positionFlag !== this.windowScrollTop)) {
          this.positionFlag = this.windowScrollTop;
          this.settingPin();
        }
      }
      this.handleMouseWheelUpDown(args);
    });

    document.addEventListener('scroll', () => {
      if (!isDesktop()) {
        this.windowScrollTop = window.pageYOffset;
        if (!this.dom.$html.hasClass(this.classes.isOpenMenu)) {
          this.settingPin();
        }
      }
    });
    $(window).on('resize orientationchange', () => {
      clearTimeout(this.timerResize);
      this.timerResize = setTimeout(() => {
        this.settingPin();
        this.setVariablesCssHeader();
      }, 200);
    });
  }

  checkSubmitFormSearch() {
    this.dom.$form.on('submit', (e) => {
      e.preventDefault();
      const $input = this.dom.$form.find('input[type="text"]');
      if ($input.val().trim() !== '') {
        window.location.href = `/search?search=${encodeURIComponent($input.val())}`;
      }
    });
  }

  settingPin(scroll = 0) {
    if (!this.dom.$html.hasClass(this.classes.actionOpenMenuMb)) {
      const scrollTop = this.windowScrollTop ? this.windowScrollTop : 0 + scroll;
      this.dom.$header.addClass('bg-pin-header');
      if (scrollTop < 200 && (!this.dom.$body.hasClass('has-color-full-header') || this.dom.$header.hasClass('highlight-main-nav'))) {
        this.dom.$header.removeClass('bg-pin-header');
      }

      if (!this.dom.$body.hasClass('first-tab-active') && this.dom.$html.hasClass('safari')) {
        this.numberScroll = 0;
      } else {
        this.numberScroll = 16;
      }
      if (scrollTop > this.numberScroll) {
        this.dom.$header.addClass(this.classes.pinHeader);
        this.dom.$body.addClass(this.classes.pinHeader);
        window.dispatchEvent(this.pinHeaderEvent);
      } else {
        this.dom.$header.removeClass(this.classes.pinHeader);
        if (!this.dom.$body.hasClass('trim-template') || this.dom.$body.hasClass('first-tab-active')) {
          this.dom.$body.removeClass(this.classes.pinHeader);
        }
        this.dom.$body.removeClass(this.classes.wheelUp).removeClass(this.classes.wheelDown);
        window.dispatchEvent(new CustomEvent('unPinHeader'));
        window.dispatchEvent(this.unPinHeaderEvent);
      }
    }
  }

  handleMouseWheelUpDown(args) {
    if (!this.dom.$html.hasClass(this.classes.actionOpenMenuMb)) {
      const scrollY = Math.round(this.dom.$body.attr(this.attributes.dataScrollY) - 400);
      // dispatch new window customEvent for mouse wheel up and down
      if (args.direction === 'down' || !window.showMainNav) {
        this.dom.$body.addClass(this.classes.wheelDown).addClass(this.classes.pinHeader).removeClass(this.classes.wheelUp)
          .attr(this.attributes.dataScrollY, Math.round(this.windowScrollTop));
        this.dom.$header.addClass(this.classes.hide);
        this.dom.$popupSearch.removeClass('active');
        this.dom.$popupSearch.find(this.tags.focusItem).attr('tabindex', '-1');
        this.dom.$body.removeClass('open-header');
        window.dispatchEvent(this.wheelUpEvent);
        window.dispatchEvent(this.wheelDownEvent2);
      } else if (scrollY > this.windowScrollTop) {
        this.dom.$header.removeClass(this.classes.hide);
        this.dom.$body.addClass(this.classes.wheelUp).removeClass(this.classes.wheelDown);
        window.dispatchEvent(this.wheelDownEvent);
        window.dispatchEvent(this.wheelUpEvent2);
      } else {
        // do nothing
      }

      if (this.windowScrollTop === 0) {
        this.dom.$body.removeClass(this.classes.wheelUp).removeClass(this.classes.wheelDown);
      }
    }
  }

  setVariablesCssHeader() {
    // set css variables for header height using other module or component: sub-navigation, banner, ...
    // const headerHeight = this.dom.$header.outerHeight();
    // const topHeaderHeight = this.$topHeader.outerHeight();
    if (this.$topHeader.length < 1) {
      this.dom.$header.addClass('no-top-header');
    }
    // set vars to :root
    // document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    // document.documentElement.style.setProperty('--height-top-header', `${topHeaderHeight}px`);
  }
}
new Header().init();
