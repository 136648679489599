export default class BadgeZipCode {
  constructor($el) {
    this.$el = $el;

    this.classes = {
      invalid: 'invalid',
    };

    this.dom = {
      $input: this.$el.find('input'),
      $buttonSubmit: this.$el.find('.js-btn-submit'),
    };

    this.inputKeyupEvent = this.inputKeyupEvent.bind(this);
    this.btnSubmitClickEvent = this.btnSubmitClickEvent.bind(this);
  }

  init() {
    if (this.dom.$input.val() !== '') {
      this.validateZip(this.dom.$input.val());
    }

    this.addEventListeners();
    this.fetchZipCodeDetailsAndAutoFillInput();
  }

  addEventListeners() {
    this.dom.$buttonSubmit.on('click', this.btnSubmitClickEvent);
    this.dom.$input.on('keyup', this.inputKeyupEvent);
  }

  btnSubmitClickEvent() {
    const url = this.$el.attr('data-url');
    const value = this.dom.$input.val();
    if (this.validateZip(value)) {
      window.location.href = `${url}?zipcode=${value}`;
    }
  }

  inputKeyupEvent(e) {
    this.validateZip(e.target.value);
    if (e.keyCode === 13) {
      this.dom.$buttonSubmit.click();
    }
  }

  validateZip(value) {
    const validateZip = /^\d{5}(-\d{4})?$/.test(value);
    if (validateZip) {
      this.$el.removeClass(this.classes.invalid);
    } else {
      this.$el.addClass(this.classes.invalid);
    }
    return validateZip;
  }

  fetchZipCodeDetailsAndAutoFillInput() {
    const apiUrl = this.$el.closest('.mod-available-offers').attr('data-url');
    $.ajax({
      type: 'GET',
      url: apiUrl,
      dataType: 'json',
      success: (data) => {
        if (data.zip) {
          this.dom.$input.val(data.zip);
        }
      },
    });
  }
}

const $validateZipCode = $('.validate-zip-code');
if ($validateZipCode.length) {
  $validateZipCode.each((_i, e) => {
    new BadgeZipCode($(e)).init();
  });
}