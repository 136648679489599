import SimpleBar from 'simplebar';

export default class Menu {
  constructor() {
    this.$el = $('#main-menu');

    this.classes = {
      isOpenMenu: 'is-open-menu',
      isOpenMenuChild: 'open-menu-child',
      isOpenChild: 'is-open-child',
      openSearch: 'open-search',
      openHeader: 'open-header',
      hoverHasSub: 'hover-has-sub',
      hovering: 'hovering',
      listMotorMenu: 'list-motor-menu',
      mouseLeave: 'mouse-leave',
      fixedBody: 'fixed-body',
      pauseVideo: 'pause-video',
      // this class actionOpenMenuMb use for detect in file Header.js
      actionOpenMenuMb: 'is-action-open-menu',
    };

    this.elementClasses = {
      listMotorMenu: `.${this.classes.listMotorMenu}`,
      mouseLeave: `.${this.classes.mouseLeave}`,
    };

    this.attribute = {
      ariaExpanded: 'aria-expanded',
    };

    this.tags = {
      focusItem: 'input, a, button',
    };

    this.dom = {
      $mainMenu: this.$el.find('.main-menu-ul'),
      $li: this.$el.find('.main-menu-ul >li>a'),
      $liLevel1: this.$el.find('.main-menu-ul >li'),
      $liLevel2: this.$el.find('.main-menu-ul .main-menu-dropdown li>a'),
      $closeLv1: this.$el.find('.close-menu-lv1'),
      $closeLv2: this.$el.find('.close-menu-lv2'),
      $header: $('#header, #main-menu-mobile'),
      $hamburgerMenu: $('.hamburger-menu'),
      $firstLinkInMenu: $('.header a:not(.skip-link), .header button').first(),
      $lastLinkInMenu: $('.header a:not(.skip-link), .header button').last(),
      $skipLink: $('.header a.skip-link'),
      $popupSearch: $('.popup-search'),
      $body: $('body'),
      $html: $('html'),
      $openSearch: this.$el.find('.main-menu-ul .open-search'),
      $bannerHome: $('.mod-banner-home .banner-bg-video'),
    };

    this.texts = {
      youtubeCom: 'youtube.com',
      youtuBe: 'youtu.be',
      vimeoCom: 'vimeo.com',
    };

    this.positionFlag = 0;

    this.elementItem = '.hamburger-menu, html, #main-menu, #header';
    this.videoItem = '.js-background-video, .bg-video';
  }

  init() {
    if (this.$el.length) {
      this.openMainMenu();
      this.clickArrowOpenDropdownMenuLevel1();
      this.clickLiOpenDropdownMenuLevel1();
      this.clickArrowOpenDropdownMenuLevel2();
      this.clickOutSite();
      this.clickLiOpenDropdownMenuLevel2();
      this.toggleTabIndexSkipLink();
      this.hoverOpenMenu();
      this.closeDropdownMenuLv1();
      this.clickTabMobile();
      this.closeDropdownMenuLv2();
      this.handleScroll();
      this.handleSearchPopup();
      this.setHeightBox();
      this.handleHover();
      this.callScrollChildDropdown();
      this.detectScrollFocus();
    }
  }

  // call custom simple scroll
  callScrollChildDropdown() {
    if (window.innerWidth > 1199) {
      /* eslint-disable-next-line */
      document.querySelectorAll('.simple-scroll-header').forEach(el => new SimpleBar(el));

      // remove tabindex inner simplebar
      const $simpleBar = $('.simple-scroll-header');
      $simpleBar.find('.simplebar-content, .-content-wrapper').removeAttr('tabindex');
    }
  }

  // detech scroll focus dropdown
  detectScrollFocus() {
    const scrollableElements = document.querySelectorAll(this.elementClasses.listMotorMenu);
    scrollableElements.forEach((element) => {
      element.addEventListener('touchmove', (event) => {
        event.stopPropagation();
      }, { passive: true });
      element.addEventListener('mousewheel', (event) => {
        event.stopPropagation();
      }, { passive: true });
      element.addEventListener('mouseleave', () => {
        $(this.elementClasses.listMotorMenu).addClass(this.classes.mouseLeave);
      });
    });
  }

  // hanlde search popup

  handleSearchPopup() {
    const $openSearch = $('.open-search');
    const $closePopup = $('.close-popup-search');
    $openSearch.on('click', () => {
      this.closeAllSubmenu();
      this.dom.$popupSearch.addClass('active');
      this.dom.$popupSearch.find(this.tags.focusItem).attr('tabindex', 0);
      this.dom.$body.addClass(this.classes.openHeader);
      setTimeout(() => {
        this.dom.$popupSearch.find('input').focus();
      }, 300);
      if ($(window).innerWidth() < 1200) {
        setTimeout(() => {
          window.scrollAgent.$scrollEvent.scrollTo('.locomotive-scroll', { offset: -10, duration: 0 });
        }, 300);
      }
    });
    $closePopup.on('click', () => {
      this.closePopupSearch();
      this.closeAllSubmenu();
    });
  }

  closePopupSearch() {
    const srOnlySpan = this.dom.$openSearch.find('.sr-only');
    this.dom.$popupSearch.removeClass('active');
    this.dom.$popupSearch.find(this.tags.focusItem).attr('tabindex', '-1');
    srOnlySpan.text('open search');
  }

  // checkScroll
  handleScroll() {
    window.scrollAgent.scrollEvent((args) => {
      this.winScrollTop = parseFloat(args.scroll.y.toFixed(2));
      if ($(this.elementClasses.mouseLeave).length && (this.positionFlag !== this.winScrollTop)) {
        this.positionFlag = this.winScrollTop;
        this.closeTransition();
      }
    });
  }

  handleHover() {
    this.$el.find('.has-sub >a').mouseenter((el) => {
      $(el.currentTarget).addClass(this.classes.hoverHasSub);
    }).mouseleave((el) => {
      if (!$(el.currentTarget).closest('.hovering').length) {
        $(el.currentTarget).removeClass(this.classes.hoverHasSub);
      }
    });
  }

  //  tab menu

  handleTabChildMenu() {
    const $tabMenuChild = $('.tab-menu-child');
    $tabMenuChild.each((_index, e) => {
      const $button = $(e).find('>button');
      $button.on('click', (el) => {
        const $elm = $(el.currentTarget);
        const $index = $elm.index();
        const $parent = $elm.parents('.tabpanel-f');
        const $listMotor = $parent.find(this.elementClasses.listMotorMenu);
        if (!$elm.hasClass('active')) {
          $button.removeClass('active');
          $elm.addClass('active');
          $listMotor.addClass('hidden');
          $listMotor.removeClass('active-transition');
          $($listMotor[$index]).removeClass('hidden');
          setTimeout(() => {
            $($listMotor[$index]).addClass('active-transition');
          }, 50);
        }
      });
    });
  }

  /* micro function */
  microOpenCloseLevel1(currentElement, openClass, isLiLv1 = false) {
    const ele = currentElement.currentTarget;
    const eleParent = $(ele).parent();
    if ($(window).width() < 1200) {
      if (!eleParent.hasClass(openClass)) {
        this.dom.$liLevel1.removeClass(openClass);
        this.dom.$liLevel1.find('> a').each((_i, el) => {
          if ($(el).attr(this.attribute.ariaExpanded)) {
            $(el).attr(this.attribute.ariaExpanded, false);
          }
        });
        eleParent.addClass(openClass);
        $(ele).attr(this.attribute.ariaExpanded, true);
        if (isLiLv1) {
          return false;
        }
      } else {
        eleParent.removeClass(openClass);
        $(ele).attr(this.attribute.ariaExpanded, false);
      }
    }
    return true;
  }
  /* end micro */

  openMainMenu() {
    this.dom.$header.on('click', '.hamburger-menu', (e) => {
      const ele = e.currentTarget;
      if ($(ele).hasClass(this.classes.isOpenMenu)) {
        $(this.elementItem).removeClass(this.classes.isOpenMenu);
        this.dom.$hamburgerMenu.find('.sr-only').text('Open menu');
        this.dom.$hamburgerMenu.attr(this.attribute.ariaExpanded, false);
        this.dom.$skipLink.removeAttr('tabindex');

        this.handleGoBackToSnapTopPositionWhenCloseMenu();
        setTimeout(() => {
          $(this.elementItem).removeClass('anima-scroll-header');
        }, 350);
      } else {
        this.handleSnapPositionWhenOpenMenuMobile();
        $(this.elementItem).addClass(this.classes.isOpenMenu);
        $(this.elementItem).addClass('anima-scroll-header');
        this.dom.$hamburgerMenu.find('.sr-only').text('Close menu');
        this.dom.$hamburgerMenu.attr(this.attribute.ariaExpanded, true);
        this.bindEventFocusOnMenu();
      }
    });
  }

  toggleTabIndexSkipLink() {
    $(window).on('resize', () => {
      if ($(window).outerWidth() < 1200) {
        this.dom.$skipLink.removeAttr('tabindex');
      }
    });
  }

  bindEventFocusOnMenu() {
    this.dom.$skipLink.attr('tabindex', -1);
    this.dom.$firstLinkInMenu.get(0).addEventListener('keydown', this.shiftTabFocusToLast.bind(this));
    this.dom.$lastLinkInMenu.get(0).addEventListener('keydown', this.tabFocusToFirst.bind(this));
  }

  tabFocusToFirst(e) {
    if (e.which === 9 && $(window).outerWidth() < 1200 && !e.shiftKey) {
      e.preventDefault();
      this.dom.$firstLinkInMenu.focus();
    }
  }

  shiftTabFocusToLast(e) {
    if (e.which === 9 && $(window).outerWidth() < 1200 && e.shiftKey) {
      e.preventDefault();
      this.dom.$lastLinkInMenu.focus();
    }
  }

  clickTabMobile() {
    const $tabMobile = $('.button-tab-mobile');
    $tabMobile.on('click', (e) => {
      const $elm = $(e.currentTarget);
      const $index = $elm.index();
      const $tabF = $('.tabpanel-f');
      $($tabF[$index]).addClass('active-mobile');
      $($tabF[$index]).parents('.has-sub').addClass('act-mb');
    });
  }

  clickArrowOpenDropdownMenuLevel1() {
    this.dom.$liLevel1.on('click', '.arrows-lv1', (e) => {
      this.microOpenCloseLevel1(e, this.classes.isOpenChild);
    });
  }

  clickLiOpenDropdownMenuLevel1() {
    this.dom.$li.on('click', (e) => this.microOpenCloseLevel1(e, this.classes.isOpenChild, true));
  }

  closeDropdownMenuLv1() {
    this.dom.$closeLv1.on('click', () => {
      this.dom.$liLevel1.removeClass(this.classes.isOpenChild);
    });
  }

  closeDropdownMenuLv2() {
    this.dom.$closeLv2.on('click', () => {
      const activeMobile = '.active-mobile';
      $(activeMobile).removeClass('active-mobile');
      $('.has-sub').removeClass('act-mb');
    });
  }

  clickArrowOpenDropdownMenuLevel2() {
    this.$el.find('.main-menu-ul').on('click', '.arrows-lv2', (e) => {
      const ele = e.currentTarget;
      const eleParent = $(ele).parent();
      if ($(window).width() < 1025) {
        if (eleParent.find('.menu-child').length && !eleParent.hasClass(this.classes.isOpenMenuChild)) {
          eleParent.addClass(this.classes.isOpenMenuChild);
        } else {
          eleParent.removeClass(this.classes.isOpenMenuChild);
        }
      }
    });
  }

  closeTransition() {
    const $mainDrop = $('.main-menu-dropdown');
    $mainDrop.addClass('transition-opa');
    this.closeAllSubmenu();
    setTimeout(() => {
      $mainDrop.removeClass('transition-opa');
    }, 300);
  }

  clickOutSite() {
    $(document).on('click', (event) => {
      if ($('.open-header').length) {
        if (!$(event.target).closest('#header.is-open-menu').length) {
          $(this.elementItem).removeClass(this.classes.isOpenMenu);
        }
        if (!$(event.target).closest('.has-sub, .control-video, .open-search, .open-search *, .popup-search, popup-search *').length ) {
          this.closeAllSubmenu();
        }
        if (!$(event.target).closest('.popup-search').length && !$(event.target).closest('.open-search').length) {
          this.closePopupSearch();
        }
      }
    });
  }

  clickLiOpenDropdownMenuLevel2() {
    this.dom.$liLevel2.on('click', (e) => {
      const ele = e.currentTarget;
      const eleParent = $(ele).parent();
      if ($(window).width() < 1025 && eleParent.find('ul').length && !eleParent.hasClass(this.classes.isOpenMenuChild)) {
        this.dom.$li.removeClass(this.classes.isOpenMenuChild);
        eleParent.addClass(this.classes.isOpenMenuChild);
        return false;
      }
      return true;
    });
  }

  closeAllSubmenu() {
    const $liHover = this.$el.find('.main-menu-ul>li.hovering');
    const $body = $('body');
    $body.removeClass(this.classes.openHeader);
    $liHover.removeClass('hovering');
    this.handlePlayVideo();
    window.scrollAgent.$scrollEvent.start();
    if (window.sliderBannerContent) {
      window.sliderBannerContent.autoplay.start();
    }
    if ($liHover.hasClass('has-sub')) {
      $liHover.find('> a').attr(this.attribute.ariaExpanded, false);
    }
  }

  handlePauseVideo() {
    const $videoJS = $(this.videoItem);
    if ($videoJS.length) {
      $videoJS.each((_index, el) => {
        const video = el;
        const $iframe = $(video).find('iframe');
        if ($iframe.length) {
          this.pauseVideoAction($iframe, video);
        }
      });
    }
  }

  pauseVideoAction ($iframe, video) {
    const url = $iframe.attr('src');
    if (!$(video).hasClass(this.classes.pauseVideo)) {
      if (url) {
        if (url.indexOf(this.texts.youtubeCom) !== -1 || url.indexOf(this.texts.youtuBe) !== -1) {
          $iframe[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
        if (url.toLowerCase().indexOf(this.texts.vimeoCom) !== -1) {
          $iframe[0].contentWindow.postMessage('{"method":"pause"}', '*');
        }
      }

      if ($iframe.parent().hasClass('kWidgetIframeContainer')) {
        setTimeout(() => {
          $iframe.parent().get(0).sendNotification('doPause');
        }, 500);
      }
    }
  }

  handlePlayVideo() {
    const $videoJS = $(this.videoItem);
    if ($videoJS.length) {
      $videoJS.each((_index, el) => {
        const video = el;
        const $iframe = $(video).find('iframe');
        if ($iframe.length) {
          this.playVideoAction($iframe, video);
        }
      });
    }
  }

  playVideoAction($iframe, video) {
    const url = $iframe.attr('src');
    if (!$(video).hasClass(this.classes.pauseVideo)) {
      if (url) {
        if (url.indexOf(this.texts.youtubeCom) !== -1 || url.indexOf(this.texts.youtuBe) !== -1) {
          $iframe[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
        }
        if (url.toLowerCase().indexOf(this.texts.vimeoCom) !== -1) {
          $iframe[0].contentWindow.postMessage('{"method":"play"}', '*');
        }
      }
      if ($iframe.parent().hasClass('kWidgetIframeContainer') && $iframe.parent().get(0)) {
        $iframe.parent().get(0).sendNotification('doPlay');
      }
    }
  }

  handleOpenMenu(ele, $body) {
    this.handlePauseVideo();
    window.scrollAgent.$scrollEvent.stop();
    $(ele).addClass(this.classes.hovering);
    if (window.sliderBannerContent) {
      window.sliderBannerContent.autoplay.stop();
    }
    $body.addClass(this.classes.openHeader);
    if ($(ele).hasClass('has-sub')) {
      $(ele).find('> a').attr(this.attribute.ariaExpanded, true);
    }
  }

  hoverOpenMenu() {
    const classHoverHeader = 'pin-color';
    const $body = $('body');
    let timeout;
    let timeHeader;
    this.dom.$li.on('click', (e) => {
      const $target = $(e.currentTarget);
      const ele = $target.parent();
      if (!$(ele).hasClass('hovering')) {
        const liHover = this.$el.find(`.main-menu-ul>li.${this.classes.hovering}`);
        this.dom.$header.addClass(classHoverHeader);
        if ($(window).outerWidth() > 1199 && liHover.length < 2) {
          clearTimeout(timeout);
          clearTimeout(timeHeader);
          if (!$(ele).hasClass(this.classes.openSearch)) {
            this.closeAllSubmenu();
          }
          timeout = setTimeout(() => {
            this.handleOpenMenu(ele, $body);
          }, 0);
        }
      } else if (!$(e.target).parents('.main-menu-dropdown').length) {
        if (!$(ele).hasClass(this.classes.openSearch)) {
          this.closeTransition();
        }
      } else {
        // do nothing
      }

      $(this.elementClasses.mouseLeave).removeClass(this.classes.mouseLeave);
    });

    $(window).on('keydown', (e) => {
      if (e.keyCode === 27 && $(window).outerWidth() > 1199) {
        clearTimeout(timeout);
        clearTimeout(timeHeader);
        this.closePopupSearch();
        timeout = setTimeout(() => {
          this.closeAllSubmenu();
        }, 50);
        timeHeader = setTimeout(() => {
          this.dom.$header.removeClass(classHoverHeader);
          this.closeAllSubmenu();
        }, 200);
      }
    });

    $(window).on('keyup', (e) => {
      if (e.keyCode === 9 && $(window).outerWidth() > 1199) {
        const $parent = $(e.target).parent();
        if ($parent.hasClass('has-sub') || $parent.hasClass(this.classes.openSearch)) {
          setTimeout(() => {
            this.closeAllSubmenu();
          }, 50);
        }
      }
    });
  }

  setHeightBox() {
    if (window.innerWidth > 1200) {
      const hContent = this.dom.$header.find('.gr-content').innerHeight();
      const boxRed = this.dom.$header.find('.box-red-content');
      const hBoxRed = boxRed.innerHeight();
      if (hContent + 32 >= hBoxRed) {
        boxRed.find('.box-red-menu').css('height', `${hContent + 42}px`);
      } else {
        boxRed.css('height', '');
      }
    }
  }

  handleSnapPositionWhenOpenMenuMobile() {
    this.snapTopPosition = $(window).scrollTop();
    clearTimeout(this.snapTopPositionTimerClose);
    this.snapTopPositionTimerOpen = setTimeout(() => {
      this.dom.$html.addClass(this.classes.fixedBody).addClass(this.classes.actionOpenMenuMb);
    }, 300);
  }

  handleGoBackToSnapTopPositionWhenCloseMenu() {
    clearTimeout(this.snapTopPositionTimerOpen);
    this.dom.$html.removeClass(this.classes.fixedBody);
    this.snapTopPositionTimerClose = setTimeout(() => {
      window.scrollTo(0, this.snapTopPosition);
      setTimeout(() => {
        this.dom.$html.removeClass(this.classes.actionOpenMenuMb);
      }, 50);
    }, 50);
  }
}

new Menu().init();
