import { throttle } from 'lodash';
import CallBackLazy from './CallBackLazy';
import { getPosition, isDesktop } from '../utils';

const callBack = new CallBackLazy();

export default class LazyLoadImage {
  constructor() {
    this.attributes = {
      dataSrc: 'data-src',
      src: 'src',
    };

    this.classes = {
      printMessage: 'print-message',
      lazy: 'lazy',
      lazyVideo: 'lazy-video',
      lazyHiddenImg: 'lazy-hidden-img',
      sliderLazy: 'slider-lazy',
      bLoadVideo: 'b-load-video',
    };

    this.elementClasses = {
      printMessage: `.${this.classes.printMessage}`,
      lazyImage: `.${this.classes.lazy}:visible`,
      lazyVideo: `.${this.classes.lazyVideo}`,
      sliderLazy: `.${this.classes.sliderLazy}`,
      lazyHiddenImg: `.${this.classes.lazyHiddenImg}`,
    };

    this.dom = {
      $body: $('body'),
      $header: $('#header'),
      $headerMainMenu: $('.main-menu, .js-nav-trim-wrap'),
      $headerHamburgerMenu: $('.hamburger-menu'),
    };

    this.windowScroll = 0;
    this.positionFlag = 0;
    this.golFlag = false;
    // Flag to track the first call
    this.initialCall = true;
    this.handleHoverHeaderEvent = this.handleHoverHeaderEvent.bind(this);
    this.loadImagesBeforePrintEvent = this.loadImagesBeforePrintEvent.bind(this);
    this.loadImagesAfterPrintEvent = this.loadImagesAfterPrintEvent.bind(this);
    this.resizeEvent = this.resizeEvent.bind(this);
    this.lazyLoadImage = this.lazyLoadImage.bind(this);
    this.lazyLoadVideo = this.lazyLoadVideo.bind(this);
    this.lazyImageIncludeHidden = this.lazyImageIncludeHidden.bind(this);
    this.lazyImmediately = this.lazyImmediately.bind(this);
  }

  init() {
    this.addSrOnlyImageInLink();
    this.lazyLoadImage();
    this.lazyImageIncludeHidden();
    this.lazyLoadVideo();
    this.addEventListener();
  }

  addEventListener() {
    this.dom.$headerHamburgerMenu.on('click', this.handleHoverHeaderEvent);
    this.dom.$headerMainMenu.on('mouseover', this.handleHoverHeaderEvent);
    $(window).on('resize orientationchange', this.resizeEvent);
    window.addEventListener('beforeprint', this.loadImagesBeforePrintEvent);
    window.addEventListener('afterprint', this.loadImagesAfterPrintEvent);

    window.scrollAgent.$scrollEvent.on('scroll', throttle((args) => {
      const scrollY = parseFloat(args.scroll.y.toFixed(2));
      if (isDesktop() && (this.positionFlag !== scrollY)) {
        this.positionFlag = scrollY;
        this.windowScroll = scrollY;
        this.onScroll();
        if (this.windowScroll > 0) {
          // Set to false after the initial call
          this.initialCall = false;
        }
      }
    }, 200));

    $(window).on('scroll', () => {
      if (!isDesktop()) {
        this.windowScroll = $(window).scrollTop();
        this.onScroll();
        if (this.windowScroll > 0) {
          // Set to false after the initial call
          this.initialCall = false;
        }
      }
    });
  }

  onScroll() {
    this.lazyLoadImage();
    this.lazyLoadVideo();
    this.lazyImageIncludeHidden();
  }

  resizeEvent() {
    this.lazyLoadImage();
    this.lazyLoadVideo();
  }

  loadImagesBeforePrintEvent() {
    this.loadImagesBeforePrint();
  }

  loadImagesAfterPrintEvent() {
    this.loadImagesAfterPrint();
  }

  handleHoverHeaderEvent(_e) {
    this.checkLazyHeader();
  }

  loadImagesBeforePrint() {
    $('style[data-vite-dev-id], link:not([media="print"]):not([media="screen"])[href^="/CSS/Powersports/"]').attr('media', 'screen');
    const $lazyImages = $(`img.${this.classes.lazy}`);
    let loadedImagesCount = 0;
    if ($lazyImages.length) {
      $('.header-mobile').append(`<div class="${this.classes.printMessage} hidden">Some images are still loading, please wait a moment before printing.</div>`);
      const $message = $(this.elementClasses.printMessage);
      $lazyImages.each((_i, e) => {
        const $img = $(e);
        $img.attr(this.attributes.src, $img.attr(this.attributes.dataSrc)).removeClass(this.classes.lazy).addClass('b-load');
        if ($img[0].complete) {
          loadedImagesCount++;
          if (loadedImagesCount === $lazyImages.length) {
            $message.remove();
          }
        } else {
          $img.on('load', () => {
            loadedImagesCount++;
            if (loadedImagesCount === $lazyImages.length) {
              $message.remove();
            }
          });
        }
      });
    }
  }

  loadImagesAfterPrint() {
    $(this.elementClasses.printMessage).remove();
  }

  addSrOnlyImageInLink() {
    this.dom.$body.find(`.${this.classes.lazy}`)
      .each((_index, element) => {
        const imageInLink = $(element).parents('a');
        const alt = $(element).attr('alt');
        if (imageInLink.length === 1 && imageInLink.find('.alt-text').length < 1 && alt) {
          imageInLink.append(`<span class="sr-only alt-text opacity-0">${alt}</span>`);
        }
      });
  }

  hasSlider(_element) {
    // code for slider lazy load here
  }

  lazyLoadImage() {
    const $lazyVisible = $(this.elementClasses.lazyImage);
    if ($lazyVisible.length) {
      $lazyVisible.each((_index, element) => {
        this.lazyLoadOneImage(element);
      });
    }
  }

  lazyLoadOneImage(element) {
    const offsetElement = this.getOffsetTop(element);
    const elementScroll = this.initialCall
      ? offsetElement - window.innerHeight
      : offsetElement - window.innerHeight - window.innerHeight / 2;
    if (elementScroll < this.windowScroll) {
      const elementTmp = element.tagName;
      callBack.call(elementTmp, element);
      this.checkLazyHasFixHeightHandler(element);
      if ($(element).parents(this.elementClasses.sliderLazy)) {
        this.hasSlider(element);
      }
    }
  }

  lazyLoadVideo() {
    if ($(this.elementClasses.lazyVideo).length && this.windowScroll > 20) {
      $(this.elementClasses.lazyVideo).each((_index, element) => {
        const offsetElement = this.getOffsetTop(element);
        const scrollBody = isDesktop() ? this.windowScroll : $(window).scrollTop();
        const elementScroll = offsetElement - window.innerHeight - window.innerHeight / 3;
        if (elementScroll < scrollBody) {
          const $attr = $(element).attr(this.attributes.dataSrc);
          $(element).attr(this.attributes.src, $attr).removeClass(this.classes.lazyVideo).addClass(this.classes.bLoadVideo);
        }
      });
    }
  }

  checkLazyHeader() {
    const $imgItem = this.dom.$headerMainMenu.find('img.lazy-img');
    if (!this.golFlag) {
      $imgItem.each((_i, el) => {
        const src = el.getAttribute(this.attributes.dataSrc);
        el.setAttribute(this.attributes.src, src);
      });
      this.golFlag = true;
    }
  }

  checkLazyHasFixHeightHandler(element) {
    if ($(element).parents('.fix-height').length) {
      $(element).on('load', () => {
        setTimeout(() => {
          window.callFixHeight();
          if (typeof window.scrollAgent.$scrollEvent.update !== 'undefined') {
            window.scrollAgent.$scrollEvent.update();
          }
        }, 200);
      });
    }
  }

  // lazy load image include hidden file
  lazyImageIncludeHidden() {
    const $lazyWrap = $(this.elementClasses.lazyHiddenImg);
    const $lazyImage = $lazyWrap.find(`.${this.classes.lazy}`);
    if ($lazyImage.length) {
      $lazyImage.each((_i, element) => {
        const elementTmp = element.tagName;
        // get offset of parent element to call lazy load image
        const offsetElement = this.getOffsetTop(element);
        const elementScroll = offsetElement - window.innerHeight - window.innerHeight / 3;
        if (elementScroll < this.windowScroll) {
          callBack.call(elementTmp, element);
          this.checkLazyHasFixHeightHandler(element);
        }
      });
    }
  }

  // lazy immediately img inner an element
  lazyImmediately(element) {
    const $lazy = $(element).find(`.${this.classes.lazy}`);
    if ($lazy.length) {
      $lazy.each((_i, elm) => {
        const elementTmp = elm.tagName;
        callBack.call(elementTmp, elm);
        this.checkLazyHasFixHeightHandler(elm);
      });
    }
  }

  getOffsetTop(element) {
    return isDesktop() ? getPosition(element).y : $(element).offset().top || 0;
  }
}

const lazyLoadImage = new LazyLoadImage();
lazyLoadImage.init();
window.lazyLoadImage = lazyLoadImage;
