import { ADA_KEYBOARD_CLASS_NAME } from '../constants';

export default class ADA {
  constructor() {
    this.$html = $('html');
    this.classes = {
      mouse: 'mouse',
      focusStatus: 'focus-status',
      otSdkShowSettings: 'ot-sdk-show-settings',
    };

    this.attribute = {
      tabIndex: 'tabindex',
    };

    this.elementClasses = {
      focusStatus: '.focus-status',
      dropdownSelectC8: '.dropdown-select-c8',
    };

    this.dom = {
      $footer: $('.footer'),
    };
  }

  init() {
    this.addListeners();
    this.setupTabIndex();
    this.initADA();
    this.addAriaHiddenIconMoon();
    this.checkLinkTagNoHref();
  }

  addListeners() {
    $('.skip-link').on('click', (event) => {
      this.skipToMainContent(event);
    });
    $(document).on('mousedown', (event) => {
      this.hiddenFocus(event);
      this.$html.removeClass(ADA_KEYBOARD_CLASS_NAME);
    });
    $(window).on('keydown', (event) => {
      this.eventWindowKeyDownAddClassHovering(event);
      // remove mouse class when press any key
      $(`.${this.classes.mouse}`).removeClass(this.classes.mouse);
      this.$html.addClass(ADA_KEYBOARD_CLASS_NAME);
    });
    $('a,button,input,div').on('focusin', (e) => {
      this.detectFocus(e);
    });
    $('[role="button"]').on('keydown', (e) => {
      this.controlKey(e);
    });

    //updateDocumentHeightWhenKeyPress
    $(document).on('keydown keypress', () => {
      if (window.scrollAgent.stopScroll) {
        return;
      }
      window.scrollAgent.$scrollEvent.update();
    });
  }

  eventWindowKeyDownAddClassHovering(event) {
    const $focus = $('a:focus');
    $('iframe').attr(this.attribute.tabIndex, '-1');
    if (event.keyCode === 40 && $focus.parent().hasClass('has-sub')) {
      event.preventDefault();
      $focus.parent().addClass('hovering');
    }
  }

  skipToMainContent(event) {
    event.preventDefault();
    $('#main-content').find('a:visible, button:visible, input:visible').eq(0).focus();
  }

  hiddenFocus(event) {
    const $targetButton = $(event.target).closest('.btn');
    $('*').removeClass(this.classes.mouse);
    $(event.target).addClass(this.classes.mouse).parent().addClass(this.classes.mouse).parent().addClass(this.classes.mouse);
    if ($targetButton.length > 0) {
      $targetButton.addClass(this.classes.mouse);
    }
    $(this.elementClasses.focusStatus).removeClass(this.classes.focusStatus);
    this.$html.addClass(this.classes.mouse);
  }

  detectFocus(e) {
    if (!$(e.target).parents('.slick-initialized').length && !$(e.target).parents('.social').length) {
      $(this.elementClasses.focusStatus).removeClass(this.classes.focusStatus);
    }
    if (!$(e.target).parents(this.elementClasses.dropdownSelectC8).length) {
      $(this.elementClasses.dropdownSelectC8).each((_i, ele) => {
        $(ele).removeClass('show focus').find('.dropdown-menu').removeClass('show');
      });
    }
    if ($(e.target).parents('.header').length) {
      window.scrollAgent.scrollTo(0, 0);
    }
  }

  setupTabIndex() {
    const $header = $('.header');
    $header.attr(this.attribute.tabIndex, '0').focus().removeAttr(this.attribute.tabIndex);
  }

  initADA() {
    $('.popup-is-open').removeAttr(this.attribute.tabIndex);
  }

  addAriaHiddenIconMoon() {
    $('.icomoon').attr('aria-hidden', true);
  }

  controlKey(e) {
    const $el = $(e.currentTarget);
    if (e.keyCode === 32) {
      e.preventDefault();
      if (!$el.hasClass('not-space')) {
        $(e.currentTarget).click();
      }
    }
  }

  removeAttrAriaDisabled(parent) {
    $(parent).find('*').removeAttr('aria-disabled');
  }

  checkLinkTagNoHref() {
    this.dom.$footer.find(`a.${this.classes.otSdkShowSettings}`).each((_i, ele) => {
      if (!$(ele).attr('href')) {
        $(ele).attr('href', 'javascript:void(0)'); // eslint-disable-line no-script-url
      }
    });
  }
}
